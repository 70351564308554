import { ApiError } from './generated'
import { ref } from 'vue'

export function getErrorMsg(err: ApiError) {
  let errorMsg = err.body?.message || err.message || 'Generic Error'

  if (err.body?.context?.errors?.length > 0) {
    errorMsg = err.body.context.errors[0].message
  }

  return errorMsg
}

export function getCookie(cname: string) {
  const cookies = ` ${document.cookie}`.split(';')

  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i].split('=')

    if (cookie[0] === ` ${cname}`) {
      return cookie[1]
    }
  }
  return ''
}

export const getBaseUrl = () =>
  import.meta.env.PROD &&
  (import.meta.env.MODE === 'production' || import.meta.env.MODE === 'stage' || import.meta.env.MODE === 'development')
    ? `${window.location.origin}/api`
    : import.meta.env.AXIOS_DEV_BASE_URL

export function handleDefaultError(err: ApiError) {
  window.$message.error(getErrorMsg(err))
}

export function useCustomFetch<DataType = unknown, ErrorType = ApiError, FetchArgs = undefined>(
  queryFn: (...args: FetchArgs extends object ? [params: FetchArgs] : []) => Promise<DataType>,
  options: TUseCustomFetchOptions<DataType, ErrorType> = {}
) {
  // merge with default options
  const { retry, retryDelay, retryTimes, avoidRefetch, onBeforeExecute, onSuccess, onError, onSettled } = {
    retry: false,
    retryDelay: 1000,
    retryTimes: 3,
    avoidRefetch: false,
    ...options,
  } as TUseCustomFetchOptions<DataType, ErrorType>

  const isFetching = ref(false)
  const isLoading = ref(false)
  const isError = ref(false)

  const data = ref<DataType>()
  const error = ref<ErrorType>()

  // internal helpers
  let retried = 0

  // hard reset
  const reset = () => {
    data.value = undefined
    error.value = undefined
    isFetching.value = false
    isLoading.value = false
    isError.value = false
    retried = 0
  }

  const fetch: TUseCustomFetchQueryArgs<DataType, FetchArgs> = async (...args) => {
    // onBeforeExecute hook
    if (onBeforeExecute) {
      if (onBeforeExecute.constructor.name === 'AsyncFunction') await onBeforeExecute()
      else onBeforeExecute()
    }

    if (isFetching.value && avoidRefetch) return data.value

    // soft-reset
    error.value = undefined
    isError.value = false
    isFetching.value = true

    try {
      if (!data.value) isLoading.value = true

      data.value = await queryFn(...args)
      retried = 0

      isLoading.value = false
      isFetching.value = false

      // onSuccess hook
      if (onSuccess) onSuccess(data.value)
      if (onSettled) onSettled(data.value, error.value)

      return data.value
    } catch (err) {
      // if retry is enabled
      if (retry && retried < (retryTimes || 0)) {
        setTimeout(async () => await fetch(...args), retryDelay)
        retried++
        return undefined
      }

      error.value = err as ErrorType
      isError.value = true
      isLoading.value = false
      isFetching.value = false
      retried = 0

      // onError hook
      if (onError) onError(error.value)
      if (onSettled) onSettled(data.value, error.value)

      return undefined
    }
  }

  return { fetch, reset, data, error, isFetching, isLoading, isError }
}

export function useLongPolling<DataType = unknown, ErrorType = ApiError>(
  queryFn: () => Promise<DataType>,
  options: TUseCustomFetchOptions<DataType, ErrorType> & {
    pollingTimeout: number
    shouldContinue: (data?: DataType, err?: ErrorType) => boolean
  } = {
    pollingTimeout: 500,
    shouldContinue: () => true,
  }
) {
  let timeoutId: NodeJS.Timeout

  const fetchResponse = useCustomFetch(queryFn, {
    ...options,
    retry: false,
    onSettled(data, err) {
      // call provided onSettled first
      options.onSettled?.(data, err)

      // start the long polling again to keep data up-to-date
      timeoutId = setTimeout(() => {
        if (options.shouldContinue(data, err)) {
          fetchResponse.fetch()
        }
      }, options.pollingTimeout)
    },
  })

  return {
    ...fetchResponse,
    cancelPolling: () => {
      clearTimeout(timeoutId)
    },
  }
}

export function useStreamingFetch<ErrorType = ApiError>(options: {
  onSuccess?: (data: string) => void
  onStreamPush?: (data: string) => void
  onEmptyStream?: () => void
  onError?: (e: ErrorType) => void
  onCancelled?: () => void
}) {
  const streamedMessage = ref<string>()
  const isFetching = ref(false)
  const isLoading = ref(false)
  const isFirstResponse = ref(false)
  const isSuccess = ref(false)
  const isError = ref(false)
  const error = ref<ErrorType>()
  const isEmpty = ref(false)
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  let stream: ReadableStream<any> | null = null
  let cancelled = false

  function cancel() {
    if (stream) {
      stream.cancel()
      stream = null
      cancelled = true
    }
  }

  async function fetchMessage(fetchURL: string, fetchOptions: RequestInit = {}, onStreamEnded?: () => void) {
    isFirstResponse.value = true
    isFetching.value = true
    isLoading.value = true
    isSuccess.value = false
    isError.value = false
    isEmpty.value = false

    // cancel the previous stream when a new one is requested
    cancel()

    const csrfToken = getCookie('csrftoken')

    const url = fetchURL.startsWith('/') ? getBaseUrl() + fetchURL : fetchURL

    try {
      // axios doesn't support streaming, so we use fetch
      stream = await fetch(url, {
        credentials: 'include',
        headers: new Headers({
          'Content-Type': 'x-www-form-urlencoded',
          'X-CSRFTOKEN': csrfToken,
        }),
        ...fetchOptions,
      })
        .then((response) => {
          isLoading.value = false
          if (response.ok) {
            isSuccess.value = true
            return response.body
          }

          isFetching.value = false
          isError.value = true
          options.onError?.(response as ErrorType)
          onStreamEnded?.()

          return null
        })
        .then((rb) => {
          if (rb) {
            const reader = rb.getReader()

            streamedMessage.value = ''

            return new ReadableStream({
              start(controller) {
                // The following function handles each data chunk
                function push() {
                  // "done" is a Boolean and value a "Uint8Array"
                  reader.read().then(({ done, value }) => {
                    // If there is no more data to read
                    if (done) {
                      if (!cancelled) {
                        controller.close()

                        isFetching.value = false
                        if (streamedMessage.value) {
                          options.onSuccess?.(streamedMessage.value)
                        } else {
                          console.warn('[WARN]: The text message stream did not return any data')
                          isEmpty.value = true
                          options.onEmptyStream?.()
                        }
                      }
                      onStreamEnded?.()
                      cancelled = false

                      return
                    }

                    new Response(value, { headers: { 'Content-Type': 'text/html' } }).text().then((r) => {
                      options.onStreamPush?.(r)
                      streamedMessage.value += r
                      isFirstResponse.value = false

                      // Get the data and send it to the browser via the controller
                      controller.enqueue(value)
                      push()
                    })
                  })
                }

                push()
              },
              cancel() {
                reader.cancel()
                options.onCancelled?.()
                onStreamEnded?.()
              },
            })
          }

          return null
        })
    } catch (e) {
      isFetching.value = false
      isError.value = true
      error.value = e as ErrorType
      options.onError?.(e as ErrorType)
      onStreamEnded?.()
    }
  }

  return {
    fetchMessage,
    cancel,
    isFetching,
    isLoading,
    isFirstResponse,
    isError,
    isEmpty,
    isSuccess,
    error,
  }
}
