/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type TextFilter = {
  invert?: boolean;
  value: (string | null);
  type?: TextFilter.type;
};
export namespace TextFilter {
  export enum type {
    TEXT = 'text',
  }
}

