/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { DynamicDatesUPPER } from './DynamicDatesUPPER';
export type DateDynamicAuxFilterValue = {
  kind?: DateDynamicAuxFilterValue.kind;
  col?: (number | string);
  value: DynamicDatesUPPER;
};
export namespace DateDynamicAuxFilterValue {
  export enum kind {
    DATE_RANGE_DYNAMIC = 'date_range_dynamic',
  }
}

