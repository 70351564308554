/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { DriverOverviewBarWidgetResponse } from './DriverOverviewBarWidgetResponse';
import type { FilterKind } from './FilterKind';
import type { GenericFilterResponse } from './GenericFilterResponse';
import type { TextWidgetResponse } from './TextWidgetResponse';
import type { TtaColumnRowsWidgetResponse } from './TtaColumnRowsWidgetResponse';
export type DriverOverviewInsightElementResponse = {
  id: string;
  kind?: 'DriverOverviewInsight';
  section_id: string;
  order_id: number;
  created: string;
  last_modified: string;
  filters: Array<GenericFilterResponse>;
  available_filters: Record<string, Array<FilterKind>>;
  driver_summary: TextWidgetResponse;
  driver_rows: TtaColumnRowsWidgetResponse;
  driver_analysis: DriverOverviewBarWidgetResponse;
  vertical_chart?: boolean;
  sentiment_toggle?: boolean;
  visible_left: DriverOverviewInsightElementResponse.visible_left;
};
export namespace DriverOverviewInsightElementResponse {
  export enum visible_left {
    DRIVER_SUMMARY = 'driver_summary',
    DRIVER_ROWS = 'driver_rows',
  }
}

