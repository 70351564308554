/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { FilterKind } from './FilterKind';
import type { GenericFilterResponse } from './GenericFilterResponse';
import type { SentimentBarChartWidgetResponse } from './SentimentBarChartWidgetResponse';
import type { SentimentGaugeChartWidgetResponse } from './SentimentGaugeChartWidgetResponse';
import type { SentimentLineChartWidgetResponse } from './SentimentLineChartWidgetResponse';
import type { SentimentSegmentedBarChartWidgetResponse } from './SentimentSegmentedBarChartWidgetResponse';
export type OverallSentimentInsightElementResponse = {
  id: string;
  kind?: 'OverallSentimentInsight';
  section_id: string;
  order_id: number;
  created: string;
  last_modified: string;
  filters: Array<GenericFilterResponse>;
  available_filters: Record<string, Array<FilterKind>>;
  sentiment_score_gauge: SentimentGaugeChartWidgetResponse;
  sentiment_score_segmented_bar_chart: SentimentSegmentedBarChartWidgetResponse;
  sentiment_score_over_time_line: SentimentLineChartWidgetResponse;
  sentiment_score_over_time_bar: SentimentBarChartWidgetResponse;
  visible_left: OverallSentimentInsightElementResponse.visible_left;
  visible_right: OverallSentimentInsightElementResponse.visible_right;
};
export namespace OverallSentimentInsightElementResponse {
  export enum visible_left {
    SENTIMENT_SCORE_GAUGE = 'sentiment_score_gauge',
    SENTIMENT_SCORE_SEGMENTED_BAR_CHART = 'sentiment_score_segmented_bar_chart',
  }
  export enum visible_right {
    SENTIMENT_SCORE_OVER_TIME_LINE = 'sentiment_score_over_time_line',
    SENTIMENT_SCORE_OVER_TIME_BAR = 'sentiment_score_over_time_bar',
  }
}

