import axios, { type AxiosRequestConfig } from 'axios'
import { getBaseUrl } from './helpers'

export const axiosConfig: AxiosRequestConfig = {
  baseURL: getBaseUrl(),
  timeout: 90000,
  validateStatus(status: number) {
    return status >= 200 && status <= 400
  },
  xsrfCookieName: 'csrftoken',
  xsrfHeaderName: 'X-CSRFTOKEN',
  withCredentials: true,
}

const API = axios.create(axiosConfig)

// set default headers
API.defaults.headers.common = {
  'Content-Type': 'application/json',
  'Accept-Language': localStorage.getItem('language') || '',
}

export default API
