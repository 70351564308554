/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type CategoryFilter = {
  invert?: boolean;
  value: (any[] | null);
  type?: CategoryFilter.type;
};
export namespace CategoryFilter {
  export enum type {
    CATEGORIES = 'categories',
  }
}

