/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type TextColumnResponse = {
  /**
   * Human-readable identifier for this column.
   */
  ref: string;
  /**
   * Type of this column.
   */
  type: TextColumnResponse.type;
  /**
   * Text value assigned to this column.
   */
  value: string;
};
export namespace TextColumnResponse {
  /**
   * Type of this column.
   */
  export enum type {
    TEXT = 'text',
  }
}

