/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AuxiliaryFieldUIResponse } from './AuxiliaryFieldUIResponse';
import type { TextToAnalyzeFieldUIResponse } from './TextToAnalyzeFieldUIResponse';
export type ColumnDetailResponse = {
  /**
   * identifies the class of the payload object
   */
  type: ColumnDetailResponse.type;
  /**
   * payload object
   */
  payload: (TextToAnalyzeFieldUIResponse | AuxiliaryFieldUIResponse);
};
export namespace ColumnDetailResponse {
  /**
   * identifies the class of the payload object
   */
  export enum type {
    TEXT_TO_ANALYZE = 'text-to-analyze',
    ROW = 'row',
  }
}

