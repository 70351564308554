import { computed, ref } from 'vue'
import type { Code } from '@/api'

const dict = ref<Record<string, Record<string, Code>>>({})
const topicFrequencyMap = ref<Record<string, number>>()

export const useWizardTopics = (projectId: string, questionRef: string) => {
  const key = `${projectId}_${questionRef}`

  if (!dict.value[key]) dict.value[key] = {}

  // generated topics from wizard project/question ref mapped
  return { generatedTopicsDict: computed(() => dict.value[key]), topicFrequencyMap }
}
