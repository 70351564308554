import * as dynamicComponents from '@/components/MDContent/components'
import type { App } from 'vue'

export const DynamicMDComponents = {
  install: (app: App<Element>) => {
    Object.entries(dynamicComponents).forEach(([key, value]) => {
      app.component(key, value)
    })
  },
}
