/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type AnyColumnResponse = {
  /**
   * Human-readable identifier for this column.
   */
  ref: string;
  /**
   * Type of this column.
   */
  type: AnyColumnResponse.type;
  /**
   * Any value assigned to this column.
   */
  value: null;
};
export namespace AnyColumnResponse {
  /**
   * Type of this column.
   */
  export enum type {
    ANY = 'any',
  }
}

