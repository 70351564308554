/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { FilterKind } from './FilterKind';
import type { GenericFilterResponse } from './GenericFilterResponse';
import type { NpsScoreBarChartWidgetResponse } from './NpsScoreBarChartWidgetResponse';
import type { NpsScoreGaugeChartWidgetResponse } from './NpsScoreGaugeChartWidgetResponse';
import type { NpsScoreLineChartWidgetResponse } from './NpsScoreLineChartWidgetResponse';
import type { NpsScorePieChartWidgetResponse } from './NpsScorePieChartWidgetResponse';
import type { NpsScoreSegmentedBarChartWidgetResponse } from './NpsScoreSegmentedBarChartWidgetResponse';
export type NpsScoreInsightElementResponse = {
  id: string;
  kind?: 'NPSScoreInsight';
  section_id: string;
  order_id: number;
  created: string;
  last_modified: string;
  filters: Array<GenericFilterResponse>;
  available_filters: Record<string, Array<FilterKind>>;
  nps_score_gauge: NpsScoreGaugeChartWidgetResponse;
  nps_score_pie: NpsScorePieChartWidgetResponse;
  nps_score_segmented_bar?: NpsScoreSegmentedBarChartWidgetResponse;
  nps_over_time_line: NpsScoreLineChartWidgetResponse;
  nps_over_time_bar: NpsScoreBarChartWidgetResponse;
  visible_left: NpsScoreInsightElementResponse.visible_left;
  visible_right: NpsScoreInsightElementResponse.visible_right;
};
export namespace NpsScoreInsightElementResponse {
  export enum visible_left {
    NPS_SCORE_GAUGE = 'nps_score_gauge',
    NPS_SCORE_PIE = 'nps_score_pie',
    NPS_SCORE_SEGMENTED_BAR = 'nps_score_segmented_bar',
  }
  export enum visible_right {
    NPS_OVER_TIME_LINE = 'nps_over_time_line',
    NPS_OVER_TIME_BAR = 'nps_over_time_bar',
  }
}

