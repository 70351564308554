/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CodeSentiments } from './CodeSentiments';
export type SentimentFilterV2 = {
  invert?: boolean;
  value: CodeSentiments;
  type?: SentimentFilterV2.type;
};
export namespace SentimentFilterV2 {
  export enum type {
    SENTIMENT_V2 = 'sentiment_v2',
  }
}

