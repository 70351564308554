<template>
  <div class="project-list-table-tags">
    <div ref="containerRef" class="tags-container">
      <TagItem v-for="tag in visibleTags" :key="tag" :tag="tag" :clickable="false" />
    </div>
    <div class="tag-actions">
      <NTooltip v-if="popoverTags.length" trigger="hover" class="max-w-40vw">
        <template #trigger>
          <NTag class="mr-2" size="small" :bordered="false">+{{ popoverTags.length }}</NTag>
        </template>
        <NSpace>
          <TagItem v-for="tag in popoverTags" :key="tag" :tag="tag" :clickable="false" />
        </NSpace>
      </NTooltip>

      <!-- TODO: Enable later once it is functional -->
      <ProjectsTagsDropdown v-if="canUserSeeDebugFeatures" :project="project" :available-tags="availableTags">
        <template #activator>
          <div class="tag-actions__tag-menu-container" :style="{ width: hovered ? '22px' : '0' }">
            <NButton strong secondary circle size="tiny" type="primary">
              <FaIcon icon="fa-plus" size="sm" />
            </NButton>
          </div>
        </template>
      </ProjectsTagsDropdown>
    </div>
  </div>
</template>

<script setup lang="ts">
import ProjectsTagsDropdown from './ProjectsTagsDropdown.vue'
import TagItem from '@/components/TagItem.vue'
import { canUserSeeDebugFeatures } from '@/plugins/posthog'
import { nextTick, onMounted, onUnmounted, ref, watch } from 'vue'
import type { ProjectListUIConfigTabResponse, ProjectListUIResponse } from '@/api'

interface IProjectsTableTagsProps {
  project: ProjectListUIResponse
  availableTags: ProjectListUIConfigTabResponse[]
  hovered?: boolean
}

const props = withDefaults(defineProps<IProjectsTableTagsProps>(), {
  availableTags: () => [],
  hovered: false,
})

const containerRef = ref<HTMLElement>()
const visibleTags = ref<string[]>([])
const popoverTags = ref<string[]>([])

const populateTags = async () => {
  if (!containerRef.value) return
  visibleTags.value = []
  popoverTags.value = []
  let overflowed = false

  for (const tag of props.project.labels) {
    // if overflowed container already, push to popover container & continue
    if (overflowed) {
      popoverTags.value.push(tag)
      continue
    }

    // not overflowing, push to visible tags
    visibleTags.value.push(tag)
    // await until DOM is updated
    await nextTick()
    // check if overflowing
    const isOverflowing = containerRef.value.scrollWidth > containerRef.value.clientWidth

    if (isOverflowing) {
      overflowed = true
      // remove last item from visible tags
      visibleTags.value.splice(-1)
      // push to popover tags
      popoverTags.value.push(tag)
    }
  }
}

watch(() => props.project, populateTags)

onMounted(() => {
  populateTags()

  window.addEventListener('resize', populateTags)
})

onUnmounted(() => {
  window.removeEventListener('resize', populateTags)
})
</script>

<style lang="scss" scoped>
.project-list-table-tags {
  display: flex;

  .tags-container {
    white-space: nowrap;
    overflow: hidden;
    margin-right: 8px;

    div:not(:last-child) {
      margin-right: 8px;
    }
  }

  .tag-actions {
    display: flex;
    margin-left: auto;
    align-items: center;

    &__tag-menu-container {
      width: 0;
      overflow: hidden;
      transition: all 0.15s ease-out;
    }
  }
}
</style>
