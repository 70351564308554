/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type EmptyFilter = {
  invert?: boolean;
  value: (boolean | null);
  type?: EmptyFilter.type;
};
export namespace EmptyFilter {
  export enum type {
    EMPTY = 'empty',
  }
}

