/* re-usable translation helpers */

import { i18n } from '@/plugins/i18n'

// if it is a built in role, get translated role name, get custom name if not
export const getRoleTranslation = (roleName = '') => {
  const isCustom = !i18n.global.te(`team.roles.${roleName}.name`)
  const name = !isCustom ? i18n.global.t(`team.roles.${roleName}.name`) : roleName
  const description = !isCustom
    ? i18n.global.t(`team.roles.${roleName}.description`)
    : i18n.global.t(`team.roles.custom.description`)

  return { name, description }
}
