/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ColumnSemanticTypes } from './ColumnSemanticTypes';
export type BoolColumn = {
  name: string;
  semantic_type?: ColumnSemanticTypes;
  ref?: string;
  type: BoolColumn.type;
  hide_for_filtering?: boolean;
};
export namespace BoolColumn {
  export enum type {
    BOOLEAN = 'boolean',
  }
}

