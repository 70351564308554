/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AuxiliaryMetadata } from './AuxiliaryMetadata';
export type AuxiliaryFieldUIResponse = {
  /**
   * Human-readable identifier for this column. The reference field is immutable and is unique among all columns within the same project.
   */
  ref: string;
  /**
   * Human-readable name for this column.
   */
  name: string;
  /**
   * Type of this column.
   */
  type: AuxiliaryFieldUIResponse.type;
  metadata: AuxiliaryMetadata;
};
export namespace AuxiliaryFieldUIResponse {
  /**
   * Type of this column.
   */
  export enum type {
    NUMERICAL = 'numerical',
    BOOLEAN = 'boolean',
    TEXT = 'text',
    DATE = 'date',
    ANY = 'any',
  }
}

