/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type SentimentFilter = {
  invert?: boolean;
  value: (any[] | null);
  type?: SentimentFilter.type;
};
export namespace SentimentFilter {
  export enum type {
    SENTIMENT = 'sentiment',
  }
}

