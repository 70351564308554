/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type RangeAuxFilterValue = {
  kind?: RangeAuxFilterValue.kind;
  col?: (number | string);
  value: Array<number>;
};
export namespace RangeAuxFilterValue {
  export enum kind {
    RANGE = 'range',
  }
}

