<template>
  <div class="default-layout-container">
    <!-- Sidebar -->
    <BaseSidebar v-model:collapsed="sidebarCollapsed" :items="menuItems">
      <template #pre>
        <RouterLink v-slot="{ navigate }" to="/projects" custom>
          <div class="caplena-logo mb-8 cursor-pointer" @click="navigate">
            <span class="caplena-logo-content gap-2">
              <NImage :width="42" :src="caplenaNoTextLogo" preview-disabled />
              <Transition name="fade-slide">
                <span v-if="!sidebarCollapsed" class="text-xl font-semibold">Caplena</span>
              </Transition>
            </span>
          </div>
        </RouterLink>
      </template>
    </BaseSidebar>

    <!-- allow teleporting secondary sidebar here -->
    <div id="secondary-sidebar" class="flex" />

    <!-- AppBar & Content -->
    <div class="default-layout-container__wrapper">
      <AppBar class="capp-header" />
      <div v-if="layoutMounted" id="capp-content" class="capp-content">
        <slot />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import AppBar from '@/components/common/AppBar.vue'
import BaseSidebar, { type TSidebarItem } from '@/components/common/BaseSidebar.vue'
import caplenaNoTextLogo from '@/assets/img/logo-no-text-v2.svg'
import { computed, onMounted, ref, watch } from 'vue'
import { itemsIfUserCanSeeDebugFeatures } from '@/plugins/posthog'
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'
import { useThemeStore } from '@/store'

defineOptions({
  name: 'DefaultLayout',
})

const i18n = useI18n()
const currentRoute = useRoute()

// TO DO: Translations
const menuItems = computed<TSidebarItem[]>(() => [
  {
    name: i18n.t('navigation.projects'),
    to: '/projects',
    icon: 'fa-folder-closed',
  },
  ...itemsIfUserCanSeeDebugFeatures<TSidebarItem>([
    {
      name: 'Debug (Public)',
      to: '/debug',
      icon: 'fa-bug',
    },
  ]),
  {
    type: 'toggler',
  },
  // example bottom item
  {
    name: i18n.t('navigation.logout'),
    to: '/logout',
    icon: 'far fa-arrow-right-from-bracket',
    buttonClass: 'mt-auto mx-auto active',
  },
])

const theme = useThemeStore()
const layoutMounted = ref(false)
const sidebarCollapsed = ref<boolean>(
  localStorage.getItem('sidebarCollapsed') ? JSON.parse(localStorage.getItem('sidebarCollapsed') as string) : false
)

onMounted(() => {
  layoutMounted.value = true
})

watch(
  () => currentRoute.fullPath,
  () => {
    if (currentRoute.fullPath && typeof currentRoute.fullPath === 'string') {
      if (currentRoute.fullPath.includes('/projects/')) sidebarCollapsed.value = true
    }
  }
)

watch(
  () => theme.isMobile,
  (newVal) => {
    sidebarCollapsed.value = typeof newVal === 'boolean' && newVal ? true : sidebarCollapsed.value
  }
)
</script>

<style lang="scss" scoped>
$headerH: 64px;

$wrapperMT: 4px;
$wrapperMB: 4px;
$wrapperMX: 4px;

$paddingX: 40px;
$contentPaddingT: 36px;
$contentPaddingB: 36px;

.default-layout-container {
  height: 100%;
  display: flex;
  overflow: hidden;

  &__wrapper {
    position: relative;
    flex: 1;
    overflow-y: hidden;
    margin: $wrapperMT $wrapperMX $wrapperMB 0;
    border: 1px solid var(--divider-color);
    transition: all var(--default-transition);
    @apply rounded-2xl;

    // size & positioning here only, to combine with content height
    .capp-header {
      height: $headerH;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      padding: 0 $paddingX;
      background: var(--card-color);
    }

    .capp-content {
      margin-top: $headerH;
      // 100% -> (header + wrapper margin + content paddings)
      height: calc(100vh - #{$headerH + $wrapperMT + $wrapperMB + $contentPaddingT + $contentPaddingB});
      overflow-y: auto;
      scroll-behavior: smooth;
      border-radius: 0;
      padding: $contentPaddingT $paddingX $contentPaddingB;
      @apply bg-card-color;
    }
  }
}

.caplena-logo {
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.caplena-logo-content {
  display: flex;
  align-items: center;
  min-width: 0;
}
</style>
