/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type DateRangeAuxFilterValue = {
  kind?: DateRangeAuxFilterValue.kind;
  col?: (number | string);
  value: Array<string>;
};
export namespace DateRangeAuxFilterValue {
  export enum kind {
    DATE_RANGE = 'date_range',
  }
}

