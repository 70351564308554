<template>
  <!-- Activator -->
  <NButton @click="visible = true">
    <template #icon>
      <FaIcon icon="far fa-lock" size="xs" />
    </template>
    {{ $t('team.deactivate') }}
  </NButton>

  <!-- Modal content -->
  <!-- TO DO: title translation -->
  <NModal v-model:show="visible" preset="confirm" title="Remove user?" :show-icon="false">
    <NSpin :show="loading">
      <div class="mt-4">
        <!-- TO DO: translations -->
        Are you sure you want to
        <strong>delete {{ user.first_name }}'s</strong>
        account?
        <br />
        <!-- TO DO: translations -->
        <strong>This cannot be undone!</strong>
      </div>

      <!-- Radio selection -->
      <NRadioGroup v-model:value="selectedAction" name="radiogroup" class="my-8">
        <NSpace vertical>
          <!-- TO DO: translations -->
          <NRadio value="transfer">
            <strong>Transfer</strong>
            all the user's assets (Projects, charts & dashboards) to the root user, before deleting the account.
          </NRadio>
          <NRadio value="delete">
            <strong>Do not transfer</strong>
            the user's assets, but delete them along with the account itself.
          </NRadio>
        </NSpace>
      </NRadioGroup>

      <NAlert class="mb-4">
        <div class="text-error text-center font-bold">delete {{ user.first_name }}</div>
      </NAlert>

      <!-- TO DO: translations -->
      <NInput v-model:value="inputText" placeholder="Type the text above into this field to confirm deletion" />
    </NSpin>

    <!-- TO DO: translations -->
    <template #action>
      <NButton
        type="error"
        size="small"
        :disabled="!isValid || loading"
        @click="$emit('click:delete', user, selectedAction, closeDialog)"
      >
        {{ $t('common.delete') }}
      </NButton>
    </template>
  </NModal>
</template>

<script setup lang="ts">
import { computed, ref, watch } from 'vue'
import type { TeamMemberResponse } from '@/api'

type TAction = 'transfer' | 'delete'

const props = defineProps<{
  user: TeamMemberResponse
  loading?: boolean
}>()

defineEmits<{
  'click:delete': [user: TeamMemberResponse, selectedAction: TAction, closeDialog: () => void]
}>()

const selectedAction = ref<TAction>('transfer')
const visible = ref(false)
const inputText = ref('')
const isValid = computed(() => `delete ${props.user.first_name}`.toLowerCase() === inputText.value.toLowerCase())

const closeDialog = () => (visible.value = false)

watch(visible, (newVal) => {
  if (newVal) inputText.value = ''
})
</script>

<style lang="scss" scoped></style>
