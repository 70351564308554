import type { TFilterConfig } from '@/components/filters'

export const getStartEndDateRangeStrings = (dateRange: Array<number>) => {
  const [start, end] = dateRange
  const startDate = new Date(start)
  const endDate = new Date(end)

  if (isNaN(startDate.getTime()) || isNaN(endDate.getTime())) {
    throw Error('Date object is not valid in encodeDateRange')
  }

  const parsedStart = startDate.toISOString()
  const parsedEnd = endDate.toISOString()

  return [parsedStart, parsedEnd]
}

export const decodeDateRange = (dateStr: string) => {
  // replace from:, to: and \
  const [from, to] = dateStr.split(';').map((dateStr: string) => dateStr.replace(/from:|to:|\\/g, ''))
  const fromDate = new Date(from)
  const toDate = new Date(to)

  if (isNaN(fromDate.getTime()) || isNaN(toDate.getTime())) {
    throw Error('Date object is not valid in decodeDateRange')
  }

  return [fromDate.getTime(), toDate.getTime()]
}

export const encodeDateRange = (dateRange: Array<number>) => {
  const [parsedStart, parsedEnd] = getStartEndDateRangeStrings(dateRange)

  return `from:${parsedStart.replace(/:/g, '\\:')};to:${parsedEnd.replace(/:/g, '\\:')}`
}

export function encodeStr(str: string, strict = false) {
  let encoded = String(str).replaceAll('\\', '\\\\')

  encoded = encoded.replaceAll(/(:|,|;)/g, '\\$1')

  if (strict) {
    encoded = encoded.replaceAll(/\./g, '\\.')
  }

  return encoded
}

export function encodeFilterQuery(constraints: { variable: string | null; value: string }[], operator = 'or') {
  // validate input object
  if (!operator) throw new Error('`operator` must not be null.')
  if (operator !== 'and' && operator !== 'or') throw new Error('`operator` must be either `and` or `or`')
  if (!constraints || !Array.isArray(constraints)) throw new Error('`constraints` must be an array.')

  // encode variables and values accordingly
  const encodedParts = constraints.map(({ variable, value }) => {
    if (variable === '') throw new Error('`variable` must be either null or a non-empty string.')
    if (value === null) throw new Error('`value` must not be null.')

    let encoded = ''

    if (variable) encoded += `${encodeStr(variable)}:`
    if (value) encoded += encodeStr(value)

    return encoded
  })

  const encodedFilterQuery = encodedParts.join(operator === 'and' ? ';' : ',')

  return encodedFilterQuery
}

export function decodeFilterQuery(str: string, operator = 'or') {
  if (!operator) throw new Error('`operator` must not be null.')
  if (operator !== 'and' && operator !== 'or') throw new Error('`operator` must be either `and` or `or`')
  if (!str) throw new Error('parameter must be of type `string`.')

  // parse filter query formula
  const separator = operator === 'and' ? ';' : ','
  const formula = []
  let currentVar = ''
  let stream = ''
  let backslashOccurred = false
  let colonOccurred = false

  // TO DO: replace and test this later
  // eslint-disable-next-line no-param-reassign
  str = str + separator
  for (let i = 0; i < str.length; i++) {
    const char = str.charAt(i)

    if (backslashOccurred) {
      backslashOccurred = false
      if (char === '\\') {
        stream += '\\'
      } else if (char === ',' || char === ';' || char === ':') {
        stream += char
      } else {
        throw new Error(`Invalid escape sequence: ` + `\\${char}`)
      }
    } else if (char === '\\') {
      backslashOccurred = true
    } else if (char === separator) {
      formula.push({ variable: currentVar === '' ? null : currentVar, value: stream })
      currentVar = ''
      stream = ''
      colonOccurred = false
    } else if (char === ';' || char === ',') {
      throw new Error(`Forbidden Operator: ${char}`)
    } else if (char === ':' && colonOccurred) {
      throw new Error(`Could not parse literal: ${currentVar}:${stream}`)
    } else if (char === ':') {
      colonOccurred = true
      currentVar = stream
      stream = ''
    } else {
      stream += char
    }
  }

  return formula
}

export const hasValue = (val: TFilterConfig['value']) =>
  (!Array.isArray(val) && val) || (Array.isArray(val) && val.length > 0)
