import API, { axiosConfig } from './caplena-api'
import { OpenAPI } from './generated'
import { getBaseUrl, getCookie } from './helpers'

OpenAPI.BASE = `${getBaseUrl()}/ui`
OpenAPI.WITH_CREDENTIALS = axiosConfig.withCredentials || true
// When it is a function, this is getting the headers from API instance and including for every request
OpenAPI.HEADERS = async () => {
  const headers = {
    ...(API.defaults.headers.common as Record<string, string>),
  }
  const csrftoken = getCookie(axiosConfig.xsrfCookieName || 'csrftoken')
  const xsrfHeaderName = axiosConfig.xsrfHeaderName || 'X-CSRFTOKEN'

  if (csrftoken) {
    headers[xsrfHeaderName] = csrftoken
  }

  return headers
}

const STATUS_JSON_URI = 'https://s3.eu-central-1.amazonaws.com/codit-public/status.json'

export * from './generated'
export * from './helpers'
export { API, STATUS_JSON_URI }
