/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type ReviewedFilter = {
  invert?: boolean;
  value: (boolean | null);
  type?: ReviewedFilter.type;
};
export namespace ReviewedFilter {
  export enum type {
    REVIEWED = 'reviewed',
  }
}

