<template>
  <NConfigProvider
    :theme-overrides="theme.overrides"
    :breakpoints="breakpoints"
    :locale="naiveLocale?.locale"
    :date-locale="naiveLocale?.dateLocale"
    inline-theme-disabled
  >
    <NGlobalStyle />
    <NEl class="naive-css-variables">
      <NLoadingBarProvider>
        <NDialogProvider>
          <NNotificationProvider>
            <NMessageProvider :max="5" :duration="5000">
              <NaiveWindowProvider />
              <slot />
            </NMessageProvider>
          </NNotificationProvider>
        </NDialogProvider>
      </NLoadingBarProvider>
    </NEl>
  </NConfigProvider>
</template>

<script setup lang="ts">
import { type DialogOptions, type MessageOptions, type MessageReactive } from 'naive-ui'
import { type VNodeChild, ref } from 'vue'
import { breakpoints } from '@/plugins/naive-ui/shared'
import { naiveLocale } from '@/plugins/i18n'
import { renderErrorDialogIcon } from '@/utils'
import { useDialog, useLoadingBar, useMessage, useNotification } from 'naive-ui'
import { useThemeStore } from '@/store'
import { useThrottleFn } from '@vueuse/core'

// to be able to access providers through window, globally
const NaiveWindowProvider = () => {
  const dialog = useDialog()
  const message = useMessage()

  const previousMsg = ref<string | (() => VNodeChild)>()

  // overwrite error dialog icon and button props globally
  window.$dialog = {
    ...dialog,
    error: (options: DialogOptions) =>
      dialog.error({
        icon: renderErrorDialogIcon,
        positiveButtonProps: {
          secondary: true,
        },
        negativeButtonProps: {
          quaternary: true,
          ghost: false,
        },
        ...options,
      }),
  }

  // Throttle the error message function
  const throttledErrorMessage = useThrottleFn(
    (content: string | (() => VNodeChild), options?: MessageOptions) => message.error(content, { ...options }),
    750
  )

  window.$message = {
    ...message,
    error: (content: string | (() => VNodeChild), options?: MessageOptions) => {
      if (previousMsg.value === content) {
        previousMsg.value = content
        return throttledErrorMessage(content, options) as unknown as MessageReactive
      } else {
        previousMsg.value = content
        return message.error(content, { ...options })
      }
    },
  }

  window.$notification = useNotification()
  window.$loadingBar = useLoadingBar()
  return undefined
}
const theme = useThemeStore()
</script>

<style lang="scss" scoped>
.n-config-provider,
.naive-css-variables {
  height: 100%;
  width: 100%;
}
</style>
