<template>
  <!-- Add user modal -->
  <NModal
    :show="modelValue"
    preset="card"
    class="max-w-screen-sm rounded-lg"
    :title="$t('account.team.new_user')"
    :mask-closable="!isCreatingUser"
    :closable="!isCreatingUser"
    @close="closeModal"
  >
    <NSpin :show="isCreatingUser">
      <NForm class="mt-4">
        <NGrid cols="12" item-responsive :x-gap="12" responsive="screen">
          <NFormItemGi span="12 s:12" :label="$t('common.email')" path="email" v-bind="emailAttrs.fieldAttrs">
            <NInput v-model:value="email" placeholder="" v-on="emailAttrs.inputListeners" />
          </NFormItemGi>
          <NFormItemGi
            span="12 s:6"
            :label="$t('common.first_name')"
            path="first_name"
            v-bind="firstNameAttrs.fieldAttrs"
          >
            <NInput v-model:value="firstName" placeholder="" v-on="firstNameAttrs.inputListeners" />
          </NFormItemGi>
          <NFormItemGi span="12 s:6" :label="$t('common.last_name')" v-bind="lastNameAttrs.fieldAttrs">
            <NInput v-model:value="lastName" placeholder="" v-on="lastNameAttrs.inputListeners" />
          </NFormItemGi>
          <NFormItemGi span="12 s:12" :label="$t('common.role')" path="role" v-bind="roleAttrs.fieldAttrs">
            <OrganizationRolesDropdown v-model:value="role" v-on="roleAttrs.inputListeners" />
          </NFormItemGi>
        </NGrid>
      </NForm>
    </NSpin>

    <template #action>
      <div class="flex w-full justify-end">
        <NButton
          type="primary"
          size="small"
          :disabled="isCreatingUser || !isFormValid"
          @click="handleCreateTeamMemberClick"
        >
          {{ $t('account.team.add_user') }}
        </NButton>
      </div>
    </template>
  </NModal>
</template>

<script setup lang="ts">
import OrganizationRolesDropdown from './OrganizationRolesDropdown.vue'
import { nameRule, useValidatedForm } from '@/plugins/form-validation/'
import { number, object, string } from 'yup'
import { useI18n } from 'vue-i18n'
import { useTeamMemberCreateMutation } from '@/api/vq/organization'

defineProps<{
  modelValue?: boolean
}>()

const emit = defineEmits<{
  'update:modelValue': [value: boolean | undefined]
}>()

const i18n = useI18n()

const formSchema = object({
  first_name: nameRule.label(i18n.t('common.first_name')),
  last_name: nameRule.label(i18n.t('common.last_name')),
  email: string().email().required().label(i18n.t('common.email')),
  role: number().required().not([-1]).label(i18n.t('common.role')),
})

const {
  handleSubmit,
  resetForm,
  isFormValid,
  formFields: {
    email: [email, emailAttrs],
    first_name: [firstName, firstNameAttrs],
    last_name: [lastName, lastNameAttrs],
    role: [role, roleAttrs],
  },
} = useValidatedForm(formSchema, {
  first_name: '',
  last_name: '',
  email: '',
  role: -1,
})

const { mutateAsync: createTeamMember, isPending: isCreatingUser } = useTeamMemberCreateMutation()

const handleCreateTeamMemberClick = handleSubmit((values) => {
  createTeamMember(
    {
      requestBody: values,
    },
    {
      onSuccess: () => {
        // hide modal after successful creation
        closeModal()
        // reset new user model values
        resetForm()
        // update user count, especially important to enable/disable add user button
      },
      onError: () => {
        console.error('[ERROR]: During creating a new team member')
      },
    }
  )
})

const closeModal = () => {
  emit('update:modelValue', false)
}
</script>

<style lang="scss" scoped></style>
