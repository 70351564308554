/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { LearnsFromUIResponse } from './LearnsFromUIResponse';
import type { ProjectUIStatus } from './ProjectUIStatus';
export type TextToAnalyzeMetadataUIResponse = {
  /**
   * Number of reviewed rows for this column.
   */
  reviewed_count: number;
  /**
   * Base column that this column learns from.
   */
  learns_from?: LearnsFromUIResponse;
  /**
   * Determines whether duplicates should be grouped or not.
   */
  do_group_duplicates: boolean;
  /**
   * Determines whether the original or translated text is shown.
   */
  do_show_translations: boolean;
  question_id: string;
  nonempty_count: number;
  reviewed_nonempty_count: number;
  /**
   * Category of this column.
   */
  category?: TextToAnalyzeMetadataUIResponse.category;
  /**
   * Other columns that learn from this one.
   */
  learning_from_this?: Array<LearnsFromUIResponse>;
  /**
   * Status field indicating the analysis progress on this column.
   */
  ui_status: ProjectUIStatus;
  last_modified: string;
};
export namespace TextToAnalyzeMetadataUIResponse {
  /**
   * Category of this column.
   */
  export enum category {
    CUSTOMER_SATISFACTION = 'customer_satisfaction',
    EMPLOYEE_FEEDBACK = 'employee_feedback',
    BRAND_PERCEPTION = 'brand_perception',
    PRODUCT_PERCEPTION = 'product_perception',
    EVENT_EVALUATION = 'event_evaluation',
    LIST_ANSWERS = 'list_answers',
    OTHER = 'other',
  }
}

