import { isArray, isObject, snakeCase, transform } from 'lodash-es'

export function convertKeysToSnakeCase<T = object>(obj: Record<string, unknown>): T {
  return transform(
    obj,
    (acc: Record<string, unknown>, value: unknown, key: string, target: Record<string, unknown>) => {
      const snakeKey = isArray(target) ? key : snakeCase(String(key))

      acc[snakeKey] = isObject(value) ? convertKeysToSnakeCase(value as Record<string, unknown>) : value
    }
  ) as T
}
