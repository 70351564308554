/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type DeviceResponse = {
  id: string;
  type: DeviceResponse.type;
  phone_number?: string;
};
export namespace DeviceResponse {
  export enum type {
    BACKUP_CODES = 'backup_codes',
    SMS = 'sms',
    APP = 'app',
  }
}

