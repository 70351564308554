/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type BooleanColumnResponse = {
  /**
   * Human-readable identifier for this column.
   */
  ref: string;
  /**
   * Type of this column.
   */
  type: BooleanColumnResponse.type;
  /**
   * Boolean value assigned to this column.
   */
  value?: boolean;
};
export namespace BooleanColumnResponse {
  /**
   * Type of this column.
   */
  export enum type {
    BOOLEAN = 'boolean',
  }
}

