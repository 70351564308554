import { SubscriptionService, TaskStatus, useLongPolling } from '@/api'
import { downloadFileFromLink } from '@/utils/download'
import { ref } from 'vue'
import type { ArgTypes } from '@/api/vq/subscription'

export function useCreditTransactionListLongPolling() {
  const isDownloading = ref(false)
  const cancelled = ref(false)

  async function downloadTransactionList(queryOptions: ArgTypes<'requestCreditTransactionListAsync'>) {
    isDownloading.value = true

    const requestedTransactionList = await SubscriptionService.requestCreditTransactionListAsync(queryOptions)

    const { fetch, reset } = useLongPolling(
      async () => {
        if (requestedTransactionList?.task_id) {
          return SubscriptionService.creditTransactionListAsyncStatus({
            taskId: requestedTransactionList?.task_id || '',
          })
        }

        return Promise.resolve(undefined)
      },
      {
        onSuccess(data) {
          if (cancelled.value) {
            cancelled.value = false
            reset()
            return
          }

          if (data && data.file && data.status === TaskStatus.SUCCEEDED) {
            isDownloading.value = false

            downloadFileFromLink(data.file, `caplena-credit-transaction-list.${queryOptions.requestBody?.format}`)
          }
        },
        pollingTimeout: 1500,
        shouldContinue: (data) => {
          if (cancelled.value) {
            cancelled.value = false
            reset()
            return false
          }
          if (data?.status !== TaskStatus.SUCCEEDED) return true

          return false
        },
      }
    )

    fetch()
  }

  return {
    isDownloading,
    downloadTransactionList,
    cancelled,
  }
}
