<template>
  <div class="b-1 color-error m-1 inline-block rounded-md bg-red-100 px-4 py-2">Unresolved: {{ component }}</div>
</template>

<script lang="ts" setup>
import * as dynamicComponents from './index'
import { onMounted } from 'vue'

interface IFallbackComponentProps {
  component: string
}

const props = defineProps<IFallbackComponentProps>()

onMounted(() => {
  console.error(`Component with the name ${props.component} is not found`)
  console.error(
    `Available components are: ${Object.values(dynamicComponents)
      .map((c) => c.__name)
      .join(', ')}`
  )
})
</script>
