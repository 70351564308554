/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { FilterKind } from './FilterKind';
import type { GenericFilterResponse } from './GenericFilterResponse';
import type { TextWidgetResponse } from './TextWidgetResponse';
import type { TtaColumnRowsWidgetResponse } from './TtaColumnRowsWidgetResponse';
export type SelectedRowsInsightElementResponse = {
  id: string;
  kind?: 'SelectedRowsInsight';
  section_id: string;
  order_id: number;
  created: string;
  last_modified: string;
  filters: Array<GenericFilterResponse>;
  available_filters: Record<string, Array<FilterKind>>;
  promoters_rows: TtaColumnRowsWidgetResponse;
  promoters_summary: TextWidgetResponse;
  passives_rows: TtaColumnRowsWidgetResponse;
  passives_summary: TextWidgetResponse;
  detractors_rows: TtaColumnRowsWidgetResponse;
  detractors_summary: TextWidgetResponse;
  visible_left: SelectedRowsInsightElementResponse.visible_left;
  visible_center: SelectedRowsInsightElementResponse.visible_center;
  visible_right: SelectedRowsInsightElementResponse.visible_right;
  sentiment_toggle?: boolean;
};
export namespace SelectedRowsInsightElementResponse {
  export enum visible_left {
    PROMOTERS_ROWS = 'promoters_rows',
    PROMOTERS_SUMMARY = 'promoters_summary',
  }
  export enum visible_center {
    PASSIVES_ROWS = 'passives_rows',
    PASSIVES_SUMMARY = 'passives_summary',
  }
  export enum visible_right {
    DETRACTORS_ROWS = 'detractors_rows',
    DETRACTORS_SUMMARY = 'detractors_summary',
  }
}

