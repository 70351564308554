/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/**
 * Dummy Filter doing nothing
 */
export type NullFilter = {
  invert?: boolean;
  value?: any;
  type?: NullFilter.type;
};
export namespace NullFilter {
  export enum type {
    NONE = 'None',
  }
}

