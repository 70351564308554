// WARNING: never direct import from this file
// Theme files are loaded asynchronously

import { sharedOverrides as Shared, sharedCustomVars } from './shared'
import type { GlobalThemeOverrides } from 'naive-ui'

// Custom theme variables
export const customVars: TCustomThemeVars = {
  // overwrite custom vars below, for the light theme
  ...sharedCustomVars,
}

export const themeOverrides: GlobalThemeOverrides = {
  common: {
    ...Shared.common,
  },
  Button: {
    ...Shared.Button,
  },
  Input: {
    ...Shared.Input,
  },
  Popover: {
    ...Shared.Popover,
    color: '#ffffff',
  },
  Tooltip: {
    textColor: 'rgb(51, 54, 57)',
  },
  LoadingBar: {
    ...Shared.LoadingBar,
  },
  Checkbox: {
    ...Shared.Checkbox,
  },
  Tag: {
    ...Shared.Tag,
  },
  ColorPicker: {
    ...Shared.ColorPicker,
  },
  Form: {
    ...Shared.Form,
  },
  Dialog: {
    ...Shared.Dialog,
  },
  DataTable: {
    ...Shared.DataTable,
    tdColorHover: sharedCustomVars.cBlue[50],
    thColorHover: sharedCustomVars.cBlue[50],
  },
  Breadcrumb: {
    ...Shared.Breadcrumb,
  },
  Typography: {
    ...Shared.Typography,
  },
  Tabs: {
    ...Shared.Tabs,
  },
  Card: {
    ...Shared.Card,
  },
}
