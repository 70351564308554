<template>
  <NTooltip v-bind="mergedTooltipProps">
    <template #trigger>
      <NButton v-bind="mergedButtonProps" :class="$attrs.class">
        <FaIcon :icon="icon" :size="size" />
      </NButton>
    </template>
    <slot />
  </NTooltip>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { merge } from 'lodash-es'
import type { ButtonProps, TooltipProps } from 'naive-ui'
import type { FontAwesomeIconProps } from '@fortawesome/vue-fontawesome'
import type { TIcon } from '@/plugins/font-awesome'

defineOptions({
  inheritAttrs: false,
})

const props = withDefaults(
  defineProps<{
    icon?: TIcon
    size?: FontAwesomeIconProps['size']
    tooltipProps?: TooltipProps
    buttonProps?: ButtonProps
  }>(),
  {
    icon: 'fa-circle-info',
    size: '1x',
    tooltipProps: () => ({}),
    buttonProps: () => ({}),
  }
)

const mergedTooltipProps = computed(() =>
  merge(
    // defaults
    {
      style: {
        maxWidth: '320px',
      },
    },
    props.tooltipProps
  )
)

const mergedButtonProps = computed(() =>
  merge(
    // defaults
    {
      text: true,
      size: 'large',
      type: 'primary',
      focusable: false,
    },
    props.buttonProps
  )
)
</script>
