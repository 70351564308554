/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ColumnSemanticTypes } from './ColumnSemanticTypes';
export type DateColumn = {
  name: string;
  semantic_type?: ColumnSemanticTypes;
  ref?: string;
  type: DateColumn.type;
  hide_for_filtering?: boolean;
  min?: (string | null);
  max?: (string | null);
  has_nan?: boolean;
  has_time?: boolean;
};
export namespace DateColumn {
  export enum type {
    DATE = 'date',
  }
}

