import { library } from '@fortawesome/fontawesome-svg-core'
import type { IconName, IconPrefix } from '@fortawesome/fontawesome-svg-core'

// Regular icons
import {
  faArrowDown as farArrowDown,
  faArrowLeft as farArrowLeft,
  faArrowRight as farArrowRight,
  faArrowRightFromBracket as farArrowRightFromBracket,
  faArrowUp as farArrowUp,
  faArrowUpRightFromSquare as farArrowUpRightFromSquare,
  faArrowsRotate as farArrowsRotate,
  faBan as farBan,
  faBarChart as farBarChart,
  faBell as farBell,
  faBoxArchive as farBoxArchive,
  faBuilding as farBuilding,
  faCalendar as farCalendar,
  faChartColumn as farChartColumn,
  faChartLine as farChartLine,
  faChartLineDown as farChartLineDown,
  faChartLineUp as farChartLineUp,
  faChartPie as farChartPie,
  faChartSimple as farChartSimple,
  faChartTreeMap as farChartTreemap,
  faChevronLeft as farChevronLeft,
  faChevronRight as farChevronRight,
  faCircleChevronLeft as farCircleChevronLeft,
  faCircleChevronRight as farCircleChevronRight,
  faCircleInfo as farCircleInfo,
  faCircleNodes as farCircleNodes,
  faCircleQuestion as farCircleQuestion,
  faCircleT as farCircleT,
  faClose as farClose,
  faCog as farCog,
  faCoins as farCoins,
  faComment as farComment,
  faDatabase as farDatabase,
  faEllipsis as farEllipsis,
  faExclamationCircle as farExclamationCircle,
  faEye as farEye,
  faFaceThinking as farFaceThinking,
  faFileChartColumn as farFileChartColumn,
  faFilter as farFilter,
  faFilterList as farFilterList,
  faFrown as farFrown,
  faGauge as farGauge,
  faLinkHorizontal as farLinkHorizontal,
  faLinkHorizontalSlash as farLinkHorizontalSlash,
  faList as farList,
  faListTree as farListTree,
  faLock as farLock,
  faMeh as farMeh,
  faMessageLines as farMessageLines,
  faMicrochipAi as farMicrochipAi,
  faMinusCircle as farMinusCircle,
  faMoon as farMoon,
  faPaintbrush as farPaintBrush,
  faPen as farPen,
  faPieChart as farPieChart,
  faPizza as farPizza,
  faPlay as farPlay,
  faPlug as farPlug,
  faShieldHalved as farShieldHalved,
  faSmile as farSmile,
  faSparkles as farSparkles,
  faSpinnerThird as farSpinnerThird,
  faStar as farStar,
  faTags as farTags,
  faThumbTack as farThumbTack,
  faTrash as farTrash,
  faTrianglePersonDigging as farTrianglePersonDigging,
  faUser as farUser,
  faUserGroup as farUserGroup,
  faUserPlus as farUserPlus,
  faWallet as farWallet,
  faWifiExclamation as farWifiExclamation,
} from '@fortawesome/pro-regular-svg-icons'

// SOLID icons
import {
  faAngleRight,
  faArrowDown,
  faArrowDownWideShort,
  faArrowLeft,
  faArrowRight,
  faArrowRightArrowLeft,
  faArrowRotateRight,
  faArrowUp,
  faArrowUpWideShort,
  faBells,
  faBoxArchive,
  faBug,
  faCalendarDays,
  faCaretUp,
  faCheck,
  faCheckCircle,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faCircle,
  faCircleInfo,
  faCircleNodes,
  faCircleO,
  faCircleQuestion,
  faClose,
  faCodeBranch,
  faCog,
  faComment,
  faDash,
  faDatabase,
  faDownload,
  faEllipsis,
  faExpand,
  faFile,
  faFilter,
  faFolderClosed,
  faGear,
  faGlobe,
  faGripDotsVertical,
  faGripLines,
  faHeading,
  faHome,
  faHourglassHalf,
  faInfo,
  faKeyboard,
  faLock,
  faMagnifyingGlassDollar,
  faMerge,
  faMinus,
  faMobileScreenButton,
  faPaintBrush,
  faPen,
  faPenToSquare,
  faPlay,
  faPlug,
  faPlus,
  faQrcode,
  faRotateLeft,
  faRotateRight,
  faSearch,
  faShare,
  faShieldHalved,
  faSignalBarsFair,
  faSignalBarsGood,
  faSignalBarsStrong,
  faSort,
  faStar,
  faSun,
  faTag,
  faTags,
  faThumbTack,
  faTrash,
  faUpload,
  faUser,
  faUserAlt,
  faUserCheck,
  faUserCircle,
  faUserSecret,
  faUsersBetweenLines,
  faWandMagicSparkles,
  faXmark,
} from '@fortawesome/pro-solid-svg-icons'

// LIGHT icons
import {
  faBinarySlash as falBinarySlash,
  faCoins as falCoins,
  faEmptySet as falEmptySet,
  faFaceThinking as falFaceThinking,
  faFileChartColumn as falFileChartColumn,
} from '@fortawesome/pro-light-svg-icons'

// THIN icons
import {
  faFileImport as fatFileImport,
  faFileUpload as fatFileUpload,
  faList as fatList,
  faLock as fatLock,
  faLockOpen as fatLockOpen,
  faStars as fatStars,
  faThumbsDown as fatThumbsDown,
  faThumbsUp as fatThumbsUp,
  faUpload as fatUpload,
} from '@fortawesome/pro-thin-svg-icons'

export type TIconName = `fa-${IconName}`
export type TIcon = TIconName | `${IconPrefix} ${TIconName}`

library.add(
  faHome,
  faBug,
  farWallet,
  farUserGroup,
  faFolderClosed,
  faAngleRight,
  faExpand,
  fatList,
  fatUpload,
  fatThumbsDown,
  fatThumbsUp,
  fatLockOpen,
  fatLock,
  farTags,
  fatFileImport,
  fatStars,
  fatFileUpload,
  farWifiExclamation,
  farExclamationCircle,
  falEmptySet,
  farCalendar,
  faMerge,
  farPen,
  farThumbTack,
  faCaretUp,
  faThumbTack,
  farSpinnerThird,
  farClose,
  faPenToSquare,
  faFile,
  faShare,
  falFileChartColumn,
  faDownload,
  faGear,
  faDatabase,
  farLinkHorizontal,
  farLinkHorizontalSlash,
  farMeh,
  farFaceThinking,
  farPizza,
  farFrown,
  farSmile,
  farCircleInfo,
  farCircleQuestion,
  farChartLine,
  farChartLineUp,
  farChartLineDown,
  farChartColumn,
  farMicrochipAi,
  farBarChart,
  farCircleChevronLeft,
  farCircleChevronRight,
  farChartTreemap,
  farMoon,
  farTrash,
  farStar,
  farCog,
  farArrowDown,
  farArrowLeft,
  farArrowRight,
  farArrowUp,
  farEllipsis,
  farFilter,
  farFilterList,
  farListTree,
  farGauge,
  farCircleT,
  farMinusCircle,
  farPieChart,
  farChartPie,
  farMessageLines,
  farList,
  farChevronLeft,
  farChevronRight,
  farDatabase,
  farChartSimple,
  farLock,
  farSparkles,
  farEye,
  farFileChartColumn,
  farArrowRightFromBracket,
  farBoxArchive,
  faArrowUp,
  faArrowDown,
  faArrowLeft,
  faArrowRight,
  farBell,
  faCircle,
  faMinus,
  faSun,
  faCircleO,
  faStar,
  faClose,
  faCircleInfo,
  faBoxArchive,
  faUserCheck,
  faUserSecret,
  faCircleQuestion,
  faUserAlt,
  faSearch,
  faArrowRotateRight,
  faChevronUp,
  faChevronLeft,
  faChevronRight,
  faEllipsis,
  faChevronDown,
  faFilter,
  faCog,
  faSort,
  faMagnifyingGlassDollar,
  faTag,
  faTags,
  faUserCircle,
  faPlus,
  faPen,
  faTrash,
  faLock,
  faUpload,
  faMobileScreenButton,
  faQrcode,
  faArrowRightArrowLeft,
  faCalendarDays,
  faCodeBranch,
  faGlobe,
  faUser,
  faCheck,
  faXmark,
  faDash,
  faCheckCircle,
  faHourglassHalf,
  faUsersBetweenLines,
  faGripDotsVertical,
  faGripLines,
  faKeyboard,
  faPaintBrush,
  faShieldHalved,
  faCircleNodes,
  faPlug,
  faWandMagicSparkles,
  faRotateLeft,
  faRotateRight,
  faHeading,
  faComment,
  farComment,
  faPlay,
  farPlay,
  farCoins,
  falCoins,
  farUserPlus,
  farBuilding,
  farUser,
  farPaintBrush,
  farShieldHalved,
  farCircleNodes,
  farPlug,
  faInfo,
  falFaceThinking,
  falBinarySlash,
  farArrowsRotate,
  farBan,
  farArrowUpRightFromSquare,
  faSignalBarsFair,
  faSignalBarsGood,
  faSignalBarsStrong,
  faBells,
  faArrowUpWideShort,
  faArrowDownWideShort,
  farTrianglePersonDigging
)
