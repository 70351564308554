/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type ProjectListUIConfigTabResponse = {
  name: string;
  type: ProjectListUIConfigTabResponse.type;
  value: string;
};
export namespace ProjectListUIConfigTabResponse {
  export enum type {
    FILTER = 'filter',
    TAG = 'tag',
  }
}

