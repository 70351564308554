/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type DateColumnResponse = {
  /**
   * Human-readable identifier for this column.
   */
  ref: string;
  /**
   * Type of this column.
   */
  type: DateColumnResponse.type;
  /**
   * ISO 8601 datetime or date value assigned to this column.
   */
  value?: string;
};
export namespace DateColumnResponse {
  /**
   * Type of this column.
   */
  export enum type {
    DATE = 'date',
  }
}

