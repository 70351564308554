/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type NumericalColumnResponse = {
  /**
   * Human-readable identifier for this column.
   */
  ref: string;
  /**
   * Type of this column.
   */
  type: NumericalColumnResponse.type;
  /**
   * Numerical value assigned to this column.
   */
  value?: number;
};
export namespace NumericalColumnResponse {
  /**
   * Type of this column.
   */
  export enum type {
    NUMERICAL = 'numerical',
  }
}

