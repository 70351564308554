/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CodeMergeProps } from './CodeMergeProps';
export type TopicMergeRequest = {
  parent_topic_id: string;
  child_topic_id: string;
  parent_topic_new_props: CodeMergeProps;
  child_target_sentiment?: TopicMergeRequest.child_target_sentiment;
  parent_target_sentiment?: TopicMergeRequest.parent_target_sentiment;
};
export namespace TopicMergeRequest {
  export enum child_target_sentiment {
    SENTIMENT_POSITIVE = 'sentiment_positive',
    SENTIMENT_NEGATIVE = 'sentiment_negative',
    SENTIMENT_NEUTRAL = 'sentiment_neutral',
  }
  export enum parent_target_sentiment {
    SENTIMENT_POSITIVE = 'sentiment_positive',
    SENTIMENT_NEGATIVE = 'sentiment_negative',
    SENTIMENT_NEUTRAL = 'sentiment_neutral',
  }
}

