<template>
  <template v-for="filter in filtersConfig" :key="filter.key">
    <NTag
      v-if="(filter.value && hasValue(filter.value)) || filter.value === null"
      class="bg-card-color font-500 shadow-sm"
      round
      :closable="closable"
      :class="{ 'opacity-75': filter.loading, 'cursor-pointer': !disableClick }"
      @click="handleFilterClick(filter.key)"
      @close="handleFilterUpdate(filter.key, undefined)"
    >
      <span class="font-400 c-gray-800">{{ filter.label }}:</span>

      <span v-if="filter.value === null" class="c-gray-500 font-italic mx-1">
        &lt;{{ $t('common.empty_filter') }}&gt;
      </span>
      <span v-else-if="hasValue(filter.value) && filter.renderTagContent" class="mx-1">
        <!-- if return type of the renderTagComponent function is a component -->
        <Component
          :is="filter.renderTagContent(filter.value)"
          v-if="typeof filter.renderTagContent(filter.value) === 'object'"
        />
        <template v-else>{{ filter.renderTagContent(filter.value) }}</template>
      </span>
      <!-- no custom render, default content renders -->
      <span v-else-if="hasValue(filter.value) && filter.type === 'date'" class="mx-1">
        {{ printDate(filter.value) }}
      </span>
      <span v-else-if="hasValue(filter.value) && filter.type === 'range'" class="mx-1">
        {{ printRange(filter.value) }}
      </span>
      <span v-else class="mx-1">
        <TruncatedTooltip :label="printFilter(filter.valueLabel || filter.value)" />
      </span>

      <FaIcon v-if="filter.loading" icon="far fa-spinner-third" class="fa-spin" />
    </NTag>
  </template>
  <NButton v-if="isClearAllRendered" class="ml-2 mr-3" size="tiny" text type="info" @click="$emit('click:reset')">
    {{ $t('common.clear_all') }}
  </NButton>
</template>

<script setup lang="ts">
import TruncatedTooltip from '@/components/TruncatedTooltip.vue'
import { type TFilterConfig } from './'
import { computed, getCurrentInstance } from 'vue'
import { formatDate } from '@/plugins/i18n'
import { hasValue } from '@/utils/filters'
import { useEventBus } from '@/composables/useEventBus'

const props = withDefaults(
  defineProps<{
    filtersConfig: Array<TFilterConfig>
    closable?: boolean
    disableClick?: boolean
  }>(),
  {
    closable: true,
  }
)

const emit = defineEmits<{
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  'update:filters': [key: any, value: any]
  'click:filter': [key: string]
  'click:reset': []
}>()

const eventBus = useEventBus()

const isClearAllRendered = computed(() => {
  const currentInstance = getCurrentInstance()

  if (!currentInstance) return false

  return (
    props.filtersConfig.some((filter) => hasValue(filter.value)) &&
    currentInstance.vnode.props &&
    currentInstance.vnode?.props['onClick:reset']
  )
})

const printFilter = (val: TFilterConfig['value']) => {
  if (Array.isArray(val)) return val.length === 1 ? String(val[0]) : `(${val.length})`
  else if (typeof val === 'string' || typeof val === 'number') return String(val)
  else return ''
}

const printDate = (val: Array<number>) => {
  const [start, end] = val
  const parsedStart = new Date(start)
  const parsedEnd = new Date(end)

  return `${formatDate(parsedStart)} ~ ${formatDate(parsedEnd)}`
}

const printRange = (val: Array<number>) => {
  const [start, end] = val

  return `${start} ~ ${end}`
}

const handleFilterClick = (key: string) => {
  if (props.disableClick) return

  emit('click:filter', key)
  eventBus.emit('selected-filter-click', key)
}
// since this is used only to reset filters, value is always undefined
const handleFilterUpdate = (key: string, value: undefined) => emit('update:filters', key, value)
</script>

<style lang="scss" scoped></style>
