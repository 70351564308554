<template>
  <NPopover
    :show="dropdownVisibleModel"
    trigger="click"
    placement="bottom-start"
    raw
    :show-arrow="false"
    class="rounded-xl"
    @update:show="handleDropdownVisibleUpdate"
  >
    <template #trigger>
      <div class="line-height-0">
        <slot name="activator">
          <NButton ghost type="info" size="small" :bordered="false">
            <span class="mr-1 text-sm">{{ $t('common.filters') }}</span>
            <FaIcon class="c-transition" icon="fa-chevron-down" :rotation="dropdownVisibleModel ? 180 : undefined" />
          </NButton>
        </slot>
      </div>
    </template>

    <NLayout has-sider class="bg-card-color">
      <!-- MENU LEFT SIDE -->
      <NLayoutSider class="filter-list" :style="{ minHeight }" content-class="p-2" :native-scrollbar="false">
        <NButton
          v-for="filter in filtersConfig"
          :key="filter.key"
          class="filter-list__item"
          :class="{ selected: selectedFilterKey === filter.key }"
          block
          quaternary
          :type="selectedFilterKey === filter.key ? 'primary' : undefined"
          @click="selectedFilterKey = filter.key"
        >
          <NBadge
            v-if="filter.value && filter.value.length"
            class="absolute"
            :offset="[-1, 0]"
            :color="primaryColor"
            :style="`border-color: ${primaryColor}`"
            dot
          />

          <TruncatedTooltip
            :label="filter.label"
            :length="filter.icon ? 28 : 32"
            trigger-class="font-500 ml-2 truncate leading-normal"
          />

          <FaIcon icon="fa-chevron-right" class="c-gray-800 ml-auto" size="xs" />
        </NButton>
      </NLayoutSider>

      <NLayout
        class="filter-content bg-card-color border-l-1 b-solid b-divider"
        content-class="flex flex-col justify-between"
      >
        <template v-for="filter in filtersConfig" :key="filter.key">
          <!-- SELECT ITEM -->
          <div v-if="filter.type === EFilterType.Checkbox && selectedFilterKey === filter.key">
            <CheckboxList
              class="mb-2 px-3 pb-1"
              :items="filter.items"
              :value="filter.value"
              :value-key="filter.itemValueKey"
              :search-item-key="filter.searchItemKey"
              :input-placeholder="filter.searchPlaceholder"
              :multi-select="filter.multiple"
              :searchable="filter.searchable"
              :scroll-height="450"
              :loading="loading"
              :label="filter.label"
              @update:value="(val) => handleFilterUpdate(filter, val)"
            >
              <!-- use CheckboxList content slot dynamically -->
              <template v-if="filter.renderCheckboxContent" #checkboxContent="{ item }">
                <Component
                  :is="filter.renderCheckboxContent!(item)"
                  v-if="typeof filter.renderCheckboxContent!(item) === 'object'"
                />
                <span v-else>{{ filter.renderCheckboxContent!(item) }}</span>
              </template>

              <template #no-data>
                {{ $t('common.no_filter_data') }}
              </template>
            </CheckboxList>
          </div>

          <!-- DATE ITEM -->
          <div v-else-if="filter.type === EFilterType.Date && selectedFilterKey === filter.key" class="p-3">
            <DatePickerPanel
              :loading="loading"
              :value="filter.value"
              @update:value="(val) => handleFilterUpdate(filter, val)"
            />
          </div>

          <!-- NUMBER RANGE -->
          <div v-else-if="filter.type === EFilterType.Range && selectedFilterKey === filter.key" class="p-3">
            <div class="font-500 mb-3 text-xs">{{ filter.label }}</div>
            <RangeSlider
              :value="filter.value || [filter.minimum, filter.maximum]"
              :max="filter.maximum"
              :min="filter.minimum"
              @update:value="(val) => handleFilterUpdate(filter, val)"
            />
          </div>

          <!-- TEXT ITEM -->
          <div v-else-if="filter.type === EFilterType.SearchInput && selectedFilterKey === filter.key" class="p-3">
            <div class="font-500 text-xs">{{ filter.label }}</div>
            <!-- we need to extract first element of the filter value
              because we are converting it to [string] on update -->
            <!-- TO DO: translate placeholder -->
            <DebouncedInput
              :value="filter.value"
              class="mt-3"
              placeholder="Search column"
              clearable
              :disabled="loading"
              @update:value="handleFilterUpdate(filter, $event)"
            >
              <template #prefix>
                <NIcon>
                  <FaIcon icon="fa-search" size="xs" />
                </NIcon>
              </template>
            </DebouncedInput>
          </div>

          <!-- CUSTOM ITEM -->
          <div v-else-if="filter.type === EFilterType.Custom && selectedFilterKey === filter.key" class="p-3">
            <div class="font-500 mb-3 text-xs">{{ filter.label }}</div>
            <Component :is="filter.component" v-bind="filter.props" />
          </div>
        </template>
        <div class="flex justify-end p-3">
          <NButton
            v-if="selectedFilter && emptyFiltersAvailable"
            size="small"
            secondary
            @click="handleFilterUpdate(selectedFilter, undefined, true)"
          >
            {{ $t('common.empty_filter') }}

            <InfoIconTooltip class="ml-2" icon="far fa-circle-info" size="sm" :button-props="{ type: 'default' }">
              {{ $t('common.empty_filter_tooltip') }}
            </InfoIconTooltip>
          </NButton>
        </div>
      </NLayout>
    </NLayout>
  </NPopover>
</template>

<script setup lang="ts">
import InfoIconTooltip from '@/components/InfoIconTooltip.vue'

import CheckboxList from '@/components/CheckboxList.vue'
import DatePickerPanel from '@/components/DatePickerPanel.vue'
import DebouncedInput from '@/components/DebouncedInput.vue'
import RangeSlider from '@/components/RangeSlider.vue'
import TruncatedTooltip from '@/components/TruncatedTooltip.vue'
import { EFilterType, type TFilterConfig } from './'
import { computed, nextTick, ref, watch } from 'vue'
import { isEqual } from 'lodash-es'
import { useEventSubscription } from '@/composables/useEventBus'
import { useThemeStore } from '@/store'

interface IDropdownFilterProps {
  filtersConfig: Array<TFilterConfig>
  loading?: boolean
  minHeight?: string
  dropdownVisible?: boolean
  interactSelectedFilters?: boolean
  emptyFiltersAvailable?: boolean
}

const props = withDefaults(defineProps<IDropdownFilterProps>(), {
  interactSelectedFilters: true,
  emptyFiltersAvailable: false,
})

const emit = defineEmits<{
  'update:dropdownVisible': [value: boolean | undefined]
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  'update:filters': [key: any, value: any, label: any, isPlaceholder: boolean]
}>()

const themeStore = useThemeStore()
const primaryColor = themeStore.themeVars.primaryColor

const selectedFilterKey = ref(props.filtersConfig[0].key)
const dropdownVisibleModel = ref(false)

const selectedFilter = computed(() => props.filtersConfig.find((f) => f.key === selectedFilterKey.value))

const handleFilterUpdate = (filter: TFilterConfig, value: Array<string | number> | undefined, isEmpty?: boolean) => {
  let newValue = value

  // if this is a range and selected value is equal to original min/max
  if (filter.type === 'range' && isEqual(value, [filter.minimum, filter.maximum])) {
    newValue = undefined
  }

  emit('update:filters', filter.key, newValue, undefined, !!isEmpty)
}

const handleDropdownVisibleUpdate = (val: boolean) => {
  dropdownVisibleModel.value = val
  emit('update:dropdownVisible', val)
}

if (props.interactSelectedFilters) {
  useEventSubscription('selected-filter-click', (key: string) => {
    selectedFilterKey.value = key
    if (!dropdownVisibleModel.value) dropdownVisibleModel.value = true
  })
}

watch(
  () => props.dropdownVisible,
  (newVal) => nextTick(() => (dropdownVisibleModel.value = newVal)),
  {
    immediate: true,
  }
)
</script>

<style lang="scss" scoped>
.filter-list {
  min-height: 250px;
  max-height: 500px;
  overflow-y: auto;

  &__item {
    justify-content: flex-start;
    height: 36px;

    &.selected,
    &:hover {
      background-color: var(--n-color-hover);
      color: var(--c-gray-800);
    }

    :deep(.n-button__content) {
      flex: 1;
    }
  }
}

.filter-content {
  min-width: 273px;
  max-width: 620px;
}
</style>
