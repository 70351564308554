/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type TextAuxFilterValue = {
  kind?: TextAuxFilterValue.kind;
  col?: (number | string);
  value?: string;
};
export namespace TextAuxFilterValue {
  export enum kind {
    TEXT = 'text',
  }
}

