/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type Auxiliary = {
  ref: string;
  name: string;
  type: Auxiliary.type;
};
export namespace Auxiliary {
  export enum type {
    NUMERICAL = 'numerical',
    DATE = 'date',
    BOOLEAN = 'boolean',
    TEXT = 'text',
  }
}

