/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type DataSourceUIResponse = {
  sync_enabled: boolean;
  sync_status: DataSourceUIResponse.sync_status;
  last_synced?: string;
  last_synced_responses: number;
};
export namespace DataSourceUIResponse {
  export enum sync_status {
    IN_PROGRESS = 'in_progress',
    FAILED = 'failed',
    COMPLETE = 'complete',
  }
}

