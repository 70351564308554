<template>
  <NLayoutHeader class="app-bar-layout">
    <!-- NOTE: Teleport location for Breadcrumb -->
    <div id="breadcrumb" />

    <!-- Theme / Notifications / User -->
    <NCard class="app-bar-actions" :bordered="false" content-style="padding: 8px 0;">
      <div class="flex gap-4">
        <NButton
          v-if="inLocalDevMode"
          type="info"
          quaternary
          icon
          circle
          @click="themeStore.setTheme(themeStore.current === 'dark' ? 'light' : 'dark')"
        >
          <FaIcon :icon="themeStore.current === 'dark' ? 'fa-sun' : 'far fa-moon'" size="lg" />
        </NButton>

        <!-- TODO: Enable later once it is functional -->
        <!-- Search -->
        <SearchInput v-if="canUserSeeDebugFeatures" collapsable circle />

        <!-- Notifications button should not be removed from DOM as "
          defaultTextColor" should be used at least once in template for theming to work properly -->
        <!-- Notifications -->
        <NButton v-show="canUserSeeDebugFeatures" quaternary icon circle>
          <NBadge>
            <FaIcon icon="far fa-bell" class="c-gray-800" size="lg" :color="defaultTextColor" />
          </NBadge>
        </NButton>

        <!-- User -->
        <Dropdown v-model="userDropdownVisible">
          <template #activator>
            <NButton text>
              <NAvatar :style="{ backgroundColor: primaryColor }" round>
                {{ user?.first_name[0] }}{{ user?.last_name[0] }}
              </NAvatar>
            </NButton>
          </template>

          <RouterLink v-slot="{ navigate }" to="/team" custom>
            <NButton icon block quaternary class="justify-start px-2" @click="navigate">
              <FaIcon icon="far fa-user-group" class="mr-2" :color="defaultTextColor" />
              {{ $t('navigation.team') }}
            </NButton>
          </RouterLink>

          <RouterLink v-slot="{ navigate }" to="/billing" custom>
            <NButton icon block quaternary class="justify-start px-2" @click="navigate">
              <FaIcon icon="far fa-wallet" class="mr-2" :color="defaultTextColor" />
              {{ $t('navigation.billing') }}
            </NButton>
          </RouterLink>

          <RouterLink v-slot="{ navigate }" to="/settings" custom>
            <NButton icon block quaternary class="justify-start px-2" @click="navigate">
              <FaIcon icon="far fa-cog" class="mr-2" :color="defaultTextColor" />
              {{ $t('navigation.settings') }}
            </NButton>
          </RouterLink>

          <RouterLink v-slot="{ navigate }" to="/logout" custom>
            <NButton block quaternary class="justify-start px-2" @click="navigate">
              <FaIcon icon="far fa-arrow-right-from-bracket" class="mr-2" :color="defaultTextColor" />
              {{ $t('navigation.logout') }}
            </NButton>
          </RouterLink>
        </Dropdown>
      </div>
    </NCard>
  </NLayoutHeader>
</template>

<script setup lang="ts">
import Dropdown from '@/components/Dropdown.vue'
import SearchInput from '@/components/SearchInput.vue'
import { canUserSeeDebugFeatures } from '@/plugins/posthog'
import { computed, ref } from 'vue'
import { useAuthStore, useThemeStore } from '@/store'

const userDropdownVisible = ref(false)

const user = useAuthStore().user
const themeStore = useThemeStore()
const defaultTextColor = computed(() => themeStore.themeVars?.textColor2 || '')
const primaryColor = themeStore.themeVars.primaryColor
const inLocalDevMode = import.meta.env.COMMAND === 'serve' && import.meta.env.MODE === 'development'
</script>

<style lang="scss" scoped>
.app-bar-layout {
  display: flex;
  overflow: hidden;
  align-items: center;
  padding: 0 16px;
  border-top-left-radius: 12px;
  border-bottom: 1px solid var(--divider-color);

  .app-bar-actions {
    width: auto;
    margin-left: auto;
    overflow-y: auto;

    :deep(.n-card__content) {
      display: flex;
      align-items: center;
      padding: 8px 16px;
    }
  }
}
</style>
