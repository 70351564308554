<template>
  <FontAwesomeIcon v-bind="$attrs" :icon="icon" :size="size" />
</template>

<script lang="ts">
import { FontAwesomeIcon, type FontAwesomeIconProps } from '@fortawesome/vue-fontawesome'
import { type PropType, defineComponent } from 'vue'
import type { TIcon } from '@/plugins/font-awesome'

const IconComponent = defineComponent({
  name: 'FaIcon',
  components: {
    FontAwesomeIcon,
  },
  inheritAttrs: false,
  props: {
    icon: {
      type: String as PropType<TIcon>,
      required: true,
    },
    size: {
      type: String as PropType<FontAwesomeIconProps['size']>,
      default: '1x',
    },
  },
})

// TO DO: find out if this is possible to do in setup syntax.
// leaving it as is unless we find a solution
export default IconComponent as typeof IconComponent & Omit<typeof FontAwesomeIcon, 'icon'>
</script>

<style lang="scss" scoped></style>
