<template>
  <div class="b b-gray-400 inline-block rounded-xl px-2 py-1" :class="clickable ? 'cursor-pointer' : ''">
    <div class="flex items-stretch gap-2">
      <div class="w-1 rounded-sm" :style="{ backgroundColor: categoryColor || 'var(--c-gray-200)' }" />
      <div class="max-w-120px flex-col">
        <!-- Category -->
        <div v-if="category" class="flex items-center">
          <NTooltip :disabled="disableCategoryTooltip">
            <template #trigger>
              <span
                ref="categoryRef"
                class="w-100% overflow-hidden text-ellipsis whitespace-nowrap text-xs font-semibold uppercase"
                :style="{ color: categoryColor }"
              >
                {{ category }}
              </span>
            </template>

            {{ category }}
          </NTooltip>
        </div>

        <!-- Topic -->
        <div v-if="topicLabel" class="flex items-center">
          <NTooltip :disabled="disableTopicTooltip">
            <template #trigger>
              <span ref="topicRef" class="w-100% overflow-hidden text-ellipsis whitespace-nowrap text-xs">
                {{ topicLabel }}
              </span>
            </template>

            {{ topicLabel }}
          </NTooltip>
        </div>
      </div>
      <div v-if="showSentiment" class="w-1px bg-divider" />
      <FaIcon
        v-if="showSentiment"
        class="self-center"
        :icon="getSentimentInformation(sentiment).icon"
        :color="getSentimentInformation(sentiment).color"
        size="lg"
      />

      <NButton
        v-if="!readonly"
        :disabled="loading"
        class="ml-1 h-auto px-1 py-0.5"
        quaternary
        size="tiny"
        @click.stop="$emit('click:delete')"
      >
        <FaIcon icon="fa-times" size="lg" color="neutral-400" />
      </NButton>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ProjectCodingRowTextUIResponse, TopicFilterSentiments, TopicSentimentsInResults } from '@/api'
import { computed, ref } from 'vue'
import { useSentimentMap } from './insight-elements/helpers'

interface ICategoryTopicTagProps {
  category?: string
  topicLabel?: string
  sentiment?: TopicSentimentsInResults | ProjectCodingRowTextUIResponse.sentiment_overall | TopicFilterSentiments
  categoryColor?: string
  showSentiment?: boolean
  readonly?: boolean
  loading?: boolean
  clickable?: boolean
}

withDefaults(defineProps<ICategoryTopicTagProps>(), { readonly: true })
defineEmits<{ 'click:delete': [] }>()

const categoryRef = ref<HTMLElement>()
const topicRef = ref<HTMLElement>()

const sentimentMap = useSentimentMap()

const getSentimentInformation = (
  sentiment?: TopicSentimentsInResults | null | ProjectCodingRowTextUIResponse.sentiment_overall | TopicFilterSentiments
) => {
  if (!sentiment) return sentimentMap[TopicSentimentsInResults.NEUTRAL]

  return sentimentMap[sentiment]
}

const disableCategoryTooltip = computed(() => {
  if (!categoryRef.value) return true

  return categoryRef.value.offsetWidth >= categoryRef.value.scrollWidth
})

const disableTopicTooltip = computed(() => {
  if (!topicRef.value) return true

  return topicRef.value.offsetWidth >= topicRef.value.scrollWidth
})
</script>
