/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { DeviceResponse } from './DeviceResponse';
export type LoginResponse = {
  next_step: LoginResponse.next_step;
  redirect_to?: string;
  device?: DeviceResponse;
  devices?: Array<DeviceResponse>;
};
export namespace LoginResponse {
  export enum next_step {
    SSO = 'sso',
    PASSWORD = 'password',
    _2FA_DEVICE = '2fa_device',
    _2FA_TOKEN = '2fa_token',
    DONE = 'done',
  }
}

