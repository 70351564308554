// WARNING: never direct import from this file
// Theme files are loaded asynchronously

import { sharedOverrides as Shared, sharedCustomVars } from './shared'
import type { GlobalThemeOverrides } from 'naive-ui'

// Custom theme variables
export const customVars: TCustomThemeVars = {
  ...sharedCustomVars,
  // overwrite custom vars below, for the dark theme
  cBase: 'rgb(16, 16, 20)',
}

export const themeOverrides: GlobalThemeOverrides = {
  common: {
    ...Shared.common,
    // other generic
    actionColor: 'rgba(255, 255, 255, 0.06)',
    avatarColor: 'rgba(255, 255, 255, 0.18)',
    baseColor: '#000',
    bodyColor: customVars.cBase,
    borderColor: 'rgba(255, 255, 255, 0.24)',
    borderRadius: '8px',
    borderRadiusSmall: '8px',
    boxShadow1: '0 1px 2px -2px rgba(0, 0, 0, .24), 0 3px 6px 0 rgba(0, 0, 0, .18), 0 5px 12px 4px rgba(0, 0, 0, .12)',
    boxShadow2: '0 3px 6px -4px rgba(0, 0, 0, .24), 0 6px 12px 0 rgba(0, 0, 0, .16), 0 9px 18px 8px rgba(0, 0, 0, .10)',
    boxShadow3:
      '0 6px 16px -9px rgba(0, 0, 0, .08), 0 9px 28px 0 rgba(0, 0, 0, .05), 0 12px 48px 16px rgba(0, 0, 0, .03)',
    buttonColor2: 'rgba(255, 255, 255, .08)',
    buttonColor2Hover: 'rgba(255, 255, 255, .12)',
    buttonColor2Pressed: 'rgba(255, 255, 255, .08)',
    cardColor: 'rgb(24, 24, 28)',
    clearColor: 'rgba(255, 255, 255, 0.38)',
    clearColorHover: 'rgba(255, 255, 255, 0.48)',
    clearColorPressed: 'rgba(255, 255, 255, 0.3)',
    closeColorHover: 'rgba(255, 255, 255, .12)',
    closeColorPressed: 'rgba(255, 255, 255, .08)',
    closeIconColor: 'rgba(255, 255, 255, 0.52)',
    closeIconColorHover: 'rgba(255, 255, 255, 0.52)',
    closeIconColorPressed: 'rgba(255, 255, 255, 0.52)',
    codeColor: 'rgba(255, 255, 255, 0.12)',
    cubicBezierEaseIn: 'cubic-bezier(.4, 0, 1, 1)',
    cubicBezierEaseInOut: 'cubic-bezier(.4, 0, .2, 1)',
    cubicBezierEaseOut: 'cubic-bezier(0, 0, .2, 1)',
    dividerColor: 'rgba(255, 255, 255, 0.09)',
    hoverColor: 'rgba(255, 255, 255, 0.09)',
    iconColor: 'rgba(255, 255, 255, 0.38)',
    iconColorDisabled: 'rgba(255, 255, 255, 0.28)',
    iconColorHover: 'rgba(255, 255, 255, 0.475)',
    iconColorPressed: 'rgba(255, 255, 255, 0.30400000000000005)',
    inputColor: 'rgba(255, 255, 255, 0.1)',
    inputColorDisabled: 'rgba(255, 255, 255, 0.06)',
    invertedColor: '#000',
    modalColor: 'rgb(44, 44, 50)',
    placeholderColor: 'rgba(255, 255, 255, 0.38)',
    placeholderColorDisabled: 'rgba(255, 255, 255, 0.28)',
    popoverColor: 'rgb(72, 72, 78)',
    pressedColor: 'rgba(255, 255, 255, 0.05)',
    progressRailColor: 'rgba(255, 255, 255, 0.12)',
    railColor: 'rgba(255, 255, 255, 0.2)',
    scrollbarColor: 'rgba(255, 255, 255, 0.2)',
    scrollbarColorHover: 'rgba(255, 255, 255, 0.3)',
    tabColor: 'rgba(255, 255, 255, 0.04)',
    tableColor: 'rgb(24, 24, 28)',
    tableColorHover: 'rgba(255, 255, 255, 0.06)',
    tableColorStriped: 'rgba(255, 255, 255, 0.05)',
    tableHeaderColor: 'rgba(255, 255, 255, 0.06)',
    tagColor: 'rgba(51, 51, 51, 1)',
    textColor1: 'rgba(255, 255, 255, 0.9)',
    textColor2: 'rgba(255, 255, 255, 0.82)',
    textColor3: 'rgba(255, 255, 255, 0.52)',
    textColorBase: '#fff',
    textColorDisabled: 'rgba(255, 255, 255, 0.38)',
  },
  Button: {
    ...Shared.Button,
  },
  Input: {
    ...Shared.Input,
  },
  Popover: {
    color: '#262626',
  },
  Tooltip: {
    textColor: 'rgba(255, 255, 255, 0.9)',
  },
  LoadingBar: {
    ...Shared.LoadingBar,
  },
  Checkbox: {
    ...Shared.Checkbox,
  },
  Tag: {
    ...Shared.Tag,
    colorBordered: 'rgba(51, 51, 51, 1)',
  },
  ColorPicker: {
    ...Shared.ColorPicker,
  },
  Form: {
    ...Shared.Form,
  },
  Dialog: {
    ...Shared.Dialog,
  },
  DataTable: {
    ...Shared.DataTable,
  },
  Breadcrumb: {
    ...Shared.Breadcrumb,
  },
  Typography: {
    ...Shared.Typography,
  },
  Skeleton: {
    color: 'rgba(255, 255, 255, 0.12)',
    colorEnd: 'rgba(255, 255, 255, 0.18)',
  },
  Tabs: {
    // always needs to match body color
    colorSegment: customVars.cBase,
  },
  Card: {
    ...Shared.Card,
  },
}
