/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type SelectAuxFilterValue = {
  kind?: SelectAuxFilterValue.kind;
  col?: (number | string);
  value?: (boolean | string | number);
};
export namespace SelectAuxFilterValue {
  export enum kind {
    SELECT = 'select',
  }
}

