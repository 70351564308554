import { SubscriptionService } from '@/api'
import { useQuery } from '@tanstack/vue-query'
import type { CreditTransactionListUIConfigResponse, UICreditTransactionWithPaginationMetaResponse } from '@/api'

// All method keys in SubscriptionService
type TSubscriptionServiceMethodKeys = ClassMethodKeys<typeof SubscriptionService>
// Parameter types extractor
export type ArgTypes<K extends TSubscriptionServiceMethodKeys> = ClassMethodParamType<typeof SubscriptionService, K>

/***********/
/* QUERIES */
/***********/

export function useCreditTransactionListQuery(
  params: ArgTypes<'creditTransactionList'> = {},
  options: TCustomUseQueryOptions<UICreditTransactionWithPaginationMetaResponse> = {}
) {
  return useQuery({
    queryKey: ['credit-usage'],
    queryFn: () => SubscriptionService.creditTransactionList(params),
    ...options,
  })
}

export function useCreditTransactionListConfigQuery(
  options: TCustomUseQueryOptions<CreditTransactionListUIConfigResponse> = {}
) {
  return useQuery({
    queryKey: ['credit-usage-list-config'],
    queryFn: () => SubscriptionService.creditTransactionListConfig(),
    ...options,
  })
}
