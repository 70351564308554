/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Metadata } from './Metadata';
export type TextToAnalyze = {
  ref: string;
  name?: string;
  type: TextToAnalyze.type;
  description?: string;
  metadata?: Metadata;
};
export namespace TextToAnalyze {
  export enum type {
    TEXT_TO_ANALYZE = 'text_to_analyze',
  }
}

