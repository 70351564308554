/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CategoricalTextBarResponse } from './CategoricalTextBarResponse';
import type { CategoricalTextTreemapResponse } from './CategoricalTextTreemapResponse';
import type { FilterKind } from './FilterKind';
import type { GenericFilterResponse } from './GenericFilterResponse';
export type CategoricalTextDistributionInsightElementResponse = {
  id: string;
  kind?: 'CategoricalTextDistributionInsight';
  section_id: string;
  order_id: number;
  created: string;
  last_modified: string;
  filters: Array<GenericFilterResponse>;
  available_filters: Record<string, Array<FilterKind>>;
  categorical_text_treemap: CategoricalTextTreemapResponse;
  categorical_text_bar: CategoricalTextBarResponse;
  visible_left: CategoricalTextDistributionInsightElementResponse.visible_left;
};
export namespace CategoricalTextDistributionInsightElementResponse {
  export enum visible_left {
    CATEGORICAL_TEXT_TREEMAP = 'categorical_text_treemap',
    CATEGORICAL_TEXT_BAR = 'categorical_text_bar',
  }
}

