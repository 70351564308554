/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { DateDynamicAuxFilterValue } from './DateDynamicAuxFilterValue';
import type { DateRangeAuxFilterValue } from './DateRangeAuxFilterValue';
import type { RangeAuxFilterValue } from './RangeAuxFilterValue';
import type { SelectAuxFilterValue } from './SelectAuxFilterValue';
import type { TextAuxFilterValue } from './TextAuxFilterValue';
export type AuxiliaryFilter = {
  invert?: boolean;
  value: (DateRangeAuxFilterValue | RangeAuxFilterValue | DateDynamicAuxFilterValue | SelectAuxFilterValue | TextAuxFilterValue | null);
  type?: AuxiliaryFilter.type;
};
export namespace AuxiliaryFilter {
  export enum type {
    AUXILIARY = 'auxiliary',
  }
}

