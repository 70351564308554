/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { FilterKind } from './FilterKind';
import type { GenericFilterResponse } from './GenericFilterResponse';
import type { TextWidgetResponse } from './TextWidgetResponse';
import type { TopicsOverviewSegmentedBarWidgetResponse } from './TopicsOverviewSegmentedBarWidgetResponse';
import type { TopicsOverviewTreemapWidgetResponse } from './TopicsOverviewTreemapWidgetResponse';
import type { TtaColumnRowsWidgetResponse } from './TtaColumnRowsWidgetResponse';
export type TopicsOverviewInsightElementResponse = {
  id: string;
  kind?: 'TopicsOverviewInsight';
  section_id: string;
  order_id: number;
  created: string;
  last_modified: string;
  filters: Array<GenericFilterResponse>;
  available_filters: Record<string, Array<FilterKind>>;
  topics_overview_treemap: TopicsOverviewTreemapWidgetResponse;
  topics_overview_segmented_bar: TopicsOverviewSegmentedBarWidgetResponse;
  topics_summary: TextWidgetResponse;
  topic_rows: TtaColumnRowsWidgetResponse;
  visible_left: TopicsOverviewInsightElementResponse.visible_left;
  visible_right: TopicsOverviewInsightElementResponse.visible_right;
};
export namespace TopicsOverviewInsightElementResponse {
  export enum visible_left {
    TOPICS_OVERVIEW_TREEMAP = 'topics_overview_treemap',
    TOPICS_OVERVIEW_SEGMENTED_BAR = 'topics_overview_segmented_bar',
  }
  export enum visible_right {
    TOPICS_SUMMARY = 'topics_summary',
    TOPIC_ROWS = 'topic_rows',
  }
}

