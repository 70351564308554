import {
  API,
  ApiError,
  AuthService,
  type LoginRequest,
  LoginResponse,
  LoginService,
  type UserDetailResponse,
  type UserDetailViewPermissionsResponse,
} from '@/api'
import { computed, ref } from 'vue'
import { defineStore } from 'pinia'
import { identifyPosthogUser, resetPosthogUser } from '@/plugins/posthog'
import { identifyUserSentry } from '@/plugins/sentry'
import { queryClient } from '@/plugins/vue-query'

const sudoTokenValidMins = 15

export const useAuthStore = defineStore('auth', () => {
  // state
  const user = ref<UserDetailResponse | null>(null)
  const isLoggedIn = computed(() => !!user.value)
  const hasOrganization = computed(() => !!user.value?.organization)
  const isOrganizationOwner = computed(() => user.value?.organization?.root_user_id === user.value?.id)
  const userLanguage = computed<TLanguage['value']>(
    () => (user.value?.language || localStorage.getItem('language') || 'en') as TLanguage['value']
  )

  const sudoToken = ref<string | undefined>()
  const sudoTokenCreated = ref<number | undefined>()

  // permission helper function
  const hasPermission = (permission: keyof UserDetailViewPermissionsResponse) => {
    if (!user.value) return false
    if (!user.value.organization) return true
    if (user.value.organization.root_user_id === user.value.id) return true
    if (user.value.view_permissions && user.value.view_permissions[permission]) return true

    return false
  }

  // actions
  async function getUser() {
    try {
      const res = await AuthService.userDetail()

      user.value = res
      identifyUserSentry(user.value)
      identifyPosthogUser(user.value.id)

      return user.value
    } catch {
      return null
    }
  }

  async function updateUser(userData: Partial<UserDetailResponse>) {
    let res

    try {
      const res = await AuthService.userUpdate({ requestBody: userData })

      // update user data after mutation
      user.value = res
      // invalidate config query since to trigger re-fetch after user changes
      queryClient.invalidateQueries({ queryKey: ['config'] })
    } catch (e: unknown) {
      return null
    }

    return res
  }

  async function login({ email, password, device_id, token }: LoginRequest) {
    const postData = { email, password, device_id, token }

    try {
      const res = await LoginService.login({ requestBody: postData })

      switch (res.next_step) {
        case LoginResponse.next_step.SSO:
          if (res.redirect_to) return window.location.replace(res.redirect_to)
          return { nextStep: res.next_step }
        case LoginResponse.next_step._2FA_DEVICE:
          return { nextStep: res.next_step, devices: res.devices }
        case LoginResponse.next_step._2FA_TOKEN:
          return { nextStep: res.next_step, devices: res.devices, device: res.device }
        default:
          return { nextStep: res.next_step }
      }
    } catch (err) {
      return { error: err as ApiError }
    }
  }

  // TO DO: replace with generated API
  async function logout() {
    try {
      await API.get('/auth/logout')
    } catch {
      console.error('Something went wrong when trying to logout')
    } finally {
      document.cookie = `csrftoken=; expires=Thu, 01 Jan 1970 00:00:01 GMT; path=/;`
      user.value = null
      identifyUserSentry(null)
      resetPosthogUser()
    }
  }

  function disableSudoMode() {
    sudoToken.value = undefined
    sudoTokenCreated.value = undefined
    delete API.defaults.headers.common['X-Sudo-Token']
    clearTimeout(window.sudoTimeout)
  }

  async function enableSudoMode(password: string) {
    try {
      const { token } = await AuthService.sudoMode({ requestBody: { password } })

      // save token to state & the headers
      sudoToken.value = token
      sudoTokenCreated.value = new Date().getTime()
      API.defaults.headers.common['X-Sudo-Token'] = token

      // cleanup timeout if exist & start timeout
      if (window.sudoTimeout) clearTimeout(window.sudoTimeout)
      window.sudoTimeout = setTimeout(disableSudoMode, sudoTokenValidMins * 60 * 1000)

      return { token }
    } catch (err) {
      return { error: err as ApiError }
    }
  }

  function isInSudoMode() {
    // valid case
    if (sudoToken.value && sudoTokenCreated.value) {
      const now = new Date().getTime()
      const minuteDiff = Math.abs((now - sudoTokenCreated.value) / 1000 / 60)

      return minuteDiff < sudoTokenValidMins
    }

    // cleanup if invalid
    disableSudoMode()
    return false
  }

  // TO DO: remove this later when it is not needed anymore
  /* eslint-disable @typescript-eslint/no-unused-vars, @typescript-eslint/no-empty-function */
  async function register() {}

  async function get2FAMethods() {}

  async function get2FAbackupCodes() {}

  async function generate2FAbackupCodes() {}

  async function init2FAProcess() {}

  async function generate2FAChallenge() {}

  async function validate2FAChallenge() {}

  async function delete2FAChallenge() {}

  async function get2FAProcessActive() {}

  async function confirmDeleteOrDisableAPIKey() {}

  async function changePassword() {}

  async function resetPassword() {}

  async function confirmResetPassword() {}

  async function verifyEmail() {}

  return {
    user,
    isLoggedIn,
    hasOrganization,
    isOrganizationOwner,
    userLanguage,
    sudoToken,
    sudoTokenCreated,
    isInSudoMode,
    hasPermission,
    getUser,
    login,
    logout,
    updateUser,
    enableSudoMode,
  }
})
