<template>
  <div>
    <NTabs
      :value="currentRoute.name?.toString()"
      type="segment"
      class="tabbed-view"
      animated
      v-bind="tabsProps"
      @update:value="handleTabChange"
    >
      <NTabPane v-for="tab in tabItems" :key="tab.routeName" :name="tab.routeName" :disabled="tab.disabled">
        <template #tab>
          <div class="px-4">
            <NIcon v-if="tab.icon" class="me-2">
              <FaIcon :icon="tab.icon" />
            </NIcon>
            {{ tab.label }}
          </div>
        </template>
      </NTabPane>
    </NTabs>

    <RouterView v-slot="{ Component }">
      <NCard class="rounded-xl" :bordered="false" content-style="padding: 8px 0;" v-bind="cardProps">
        <Transition name="fade" mode="out-in">
          <Component :is="Component" v-bind="$attrs" />
        </Transition>
      </NCard>
    </RouterView>
  </div>
</template>

<script setup lang="ts">
import { useRoute, useRouter } from 'vue-router'
import type { CardProps, TabsProps } from 'naive-ui'
import type { TIcon } from '@/plugins/font-awesome'

defineOptions({
  inheritAttrs: false,
})

defineProps<{
  tabItems: TTabItem[]
  cardProps?: CardProps
  tabsProps?: TabsProps
}>()

export type TTabItem = {
  label: string
  icon: TIcon
  routeName: string
  disabled?: boolean
}

const currentRoute = useRoute()
const router = useRouter()

const handleTabChange = (name: string) => {
  router.push({ name, params: currentRoute.params })
}
</script>

<style lang="scss" scoped>
.tabbed-view {
  :deep(.n-tabs-tab.n-tabs-tab--disabled) {
    opacity: 0.4;
  }
}
</style>
