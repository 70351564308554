/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type TopicColumnResponse = {
  /**
   * Unique identifier for this topic.
   */
  id: string;
  /**
   * Label for this topic.
   */
  label: string;
  /**
   * Category for this topic.
   */
  category: string;
  /**
   * Code for the inferred topic sentiment. If sentiment is disabled, the neutral topic sentiment `code` will be used.
   */
  code: number;
  /**
   * Label for the inferred topic sentiment. If sentiment is disabled, the neutral topic sentiment `label` will be used.
   */
  sentiment_label: string;
  /**
   * Sentiment with regards to this topic. If sentiment is disabled, will always be `any`.
   */
  sentiment: TopicColumnResponse.sentiment;
};
export namespace TopicColumnResponse {
  /**
   * Sentiment with regards to this topic. If sentiment is disabled, will always be `any`.
   */
  export enum sentiment {
    NEUTRAL = 'neutral',
    POSITIVE = 'positive',
    NEGATIVE = 'negative',
    ANY = 'any',
  }
}

