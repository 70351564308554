import { type MaybeRefOrGetter, type Ref, computed, isRef, ref, toValue } from 'vue'
import { type ObjectSchema } from 'yup'
import { toTypedSchema } from '@vee-validate/yup'
import { useForm } from 'vee-validate'
import type { GenericObject, Path } from 'vee-validate'
import type { Get, PartialDeep } from 'type-fest'

export type TNaiveInputPropsForValidation = {
  fieldAttrs: {
    validationStatus: 'error' | undefined
    feedback: string
  }
  inputListeners: {
    blur: () => void
  }
}

function getNaiveInputPropsForValidation(): {
  props: (state: { errors: string[]; touched: boolean }) => TNaiveInputPropsForValidation
} {
  const isBlurred = ref(false)

  return {
    props: (state: { errors: string[]; touched: boolean }) => {
      const showErrors = isBlurred.value || state.touched

      return {
        fieldAttrs: {
          validationStatus: state.errors[0] && showErrors ? 'error' : undefined,
          feedback: showErrors ? state.errors[0] : '',
        },
        inputListeners: {
          blur: () => {
            isBlurred.value = true
          },
        },
      }
    },
  }
}

export function useValidatedForm<T extends GenericObject>(
  schema: MaybeRefOrGetter<ObjectSchema<T>>,
  maybeInitialValues?: PartialDeep<T>
) {
  const schemaValue = toValue(schema)
  const formSchema = computed(() => (isRef(schema) ? toTypedSchema(schema.value) : toTypedSchema(schemaValue)))

  const { defineField, ...rest } = useForm<T>({
    validationSchema: formSchema,
    initialValues: maybeInitialValues,
  })

  const isFormValid = computed(() => rest.meta.value.valid)

  return {
    isFormValid,
    formFields: Object.keys(schemaValue.fields).reduce((obj, key) => {
      const field = schemaValue.fields[key as keyof T] as unknown as ObjectSchema<T>

      if (field.type === 'object') {
        const path = getSchemaFieldPathRecursive(field, [key])

        return {
          ...obj,
          ...path.reduce((acc, path) => {
            return {
              ...acc,
              [path]: defineField(path as MaybeRefOrGetter<Path<T>>, getNaiveInputPropsForValidation()),
            }
          }, {}),
        }
      }

      return {
        ...obj,
        [key]: defineField(key as MaybeRefOrGetter<Path<T>>, getNaiveInputPropsForValidation()),
      }
    }, {}) as {
      [K in Leafs<T>]: [Ref<Get<T, K>>, Ref<TNaiveInputPropsForValidation>]
    },
    ...rest,
  }
}

function getSchemaFieldPathRecursive<T extends GenericObject>(schema: ObjectSchema<T>, path: string[] = []): string[] {
  const fields = Object.keys(schema.fields) as (keyof T)[]

  return fields.reduce((acc, key) => {
    const field = schema.fields[key] as unknown as ObjectSchema<T>

    if (field.type === 'object') {
      return [...acc, ...getSchemaFieldPathRecursive(field, [...path, key as string])]
    }

    return [...acc, [...path, key].join('.')]
  }, [] as string[])
}
