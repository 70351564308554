<template>
  <div class="login-view-container">
    <NGrid cols="12" item-responsive>
      <NGridItem span="12 400:12 600:6 800:6 1000:4 1600:2" offset="0 400:0 600:3 800:3 1000:4 1600:5">
        <NCard>
          <NSpace justify="center">
            <NImage :width="100" :src="logoURL" preview-disabled />
          </NSpace>
          <NForm>
            <!-- Email -->
            <LoginStep
              v-if="!currentStep"
              v-model="email"
              form-path="email"
              placeholder="Email"
              :step="currentStep"
              :loading="loading"
              :form-item-props="emailAttrs.fieldAttrs"
              v-on="emailAttrs.inputListeners"
              @on:next="login(false, 'email')"
            />
            <!-- Password -->
            <LoginStep
              v-if="currentStep === LoginResponse.next_step.PASSWORD"
              v-model="password"
              type="password"
              form-path="password"
              placeholder="Password"
              :step="currentStep"
              :loading="loading"
              :form-item-props="passwordAttrs.fieldAttrs"
              v-on="passwordAttrs.inputListeners"
              @on:next="login(false, 'password')"
            />
            <!-- Auth Code -->
            <LoginStep
              v-if="
                currentStep === LoginResponse.next_step._2FA_DEVICE ||
                currentStep === LoginResponse.next_step._2FA_TOKEN
              "
              v-model="authCode"
              form-path="authCode"
              placeholder="Code"
              :step="currentStep"
              :loading="loading"
              :input-props="{
                name: 'one-time-code',
                inputmode: 'numeric',
              }"
              :form-item-props="authCodeAttrs.fieldAttrs"
              v-on="authCodeAttrs.inputListeners"
              @on:next="login(false, 'authCode')"
            >
              <!-- 2FA device select -->
              <template v-if="availableDevices && availableDevices.length && availableDevices.length > 1">
                <NButton v-if="!deviceSelectMode" type="primary" text @click="deviceSelectMode = true">
                  Show available devices
                </NButton>
                <NButton
                  v-else
                  v-for="device in availableDevices"
                  :key="device.id"
                  type="primary"
                  :disabled="loading"
                  class="mt-2 flex"
                  @click="() => select2FADevice(device.id)"
                >
                  <NText class="ml-2" code>{{ device.type }}</NText>
                </NButton>
              </template>
            </LoginStep>
          </NForm>
        </NCard>
      </NGridItem>
    </NGrid>
  </div>
</template>

<script setup lang="ts">
import LoginStep from './components/LoginStep.vue'
import logoURL from '@/assets/img/logo.svg'
import { ApiError, DeviceResponse, LoginResponse, getErrorMsg } from '@/api'
import { authCodeRule, passwordRule, useValidatedForm } from '@/plugins/form-validation'
import { object, string } from 'yup'
import { onMounted, ref } from 'vue'
import { useAuthStore } from '@/store'
import { useMessage } from 'naive-ui'
import { useRouter } from 'vue-router'
import { useUrlSearchParams } from '@vueuse/core'

const formSchema = object({
  email: string().email().required(),
  password: passwordRule,
  authCode: authCodeRule,
})

const {
  isFormValid,
  isFieldValid,
  formFields: {
    email: [email, emailAttrs],
    password: [password, passwordAttrs],
    authCode: [authCode, authCodeAttrs],
  },
} = useValidatedForm(formSchema, {
  authCode: '',
  email: '',
  password: '',
})

const auth = useAuthStore()
const router = useRouter()
const message = useMessage()
const deviceSelectMode = ref(false)
const loading = ref(false)
const currentStep = ref()
const deviceId = ref<string>()
const availableDevices = ref<DeviceResponse[]>([])
const redirectPath = useUrlSearchParams('history').go_to as string | undefined

const throwError = (msg: string | string[] = 'Something went wrong during login') => {
  if (Array.isArray(msg)) {
    msg.forEach((m) => {
      message.error(m)
      console.error(m)
    })
  } else if (typeof msg === 'string') {
    message.error(msg)
    console.error(msg)
  }
}

const redirect = (path: string | undefined = undefined) => {
  // custom url param
  if (path) router.push(path)

  // no custom url && has redirectURL
  if (redirectPath) {
    router.push(redirectPath)
    return
  }

  // default: redirect home
  router.push('/')
}

const login = async (skipFormValidation = false, field?: 'authCode' | 'email' | 'password') => {
  if (!skipFormValidation) {
    if (field && !isFieldValid(field)) return
    else if (!field && !isFormValid.value) return
  }

  loading.value = true

  const res = await auth.login({
    email: email.value,
    password: password.value,
    token: authCode.value,
    device_id: deviceId.value,
  })

  loading.value = false

  if (!res || res.error) {
    throwError(getErrorMsg(res?.error as ApiError))
    return
  }

  // login successful - no 2FA configured
  if (res.nextStep === LoginResponse.next_step.DONE) {
    await auth.getUser()
    redirect()
    return
  }

  if (res.nextStep === LoginResponse.next_step.PASSWORD) {
    // nextTick(() => passwordRef.value?.focus())
  }

  // configure UI for 2FA challenge
  if (res.nextStep === LoginResponse.next_step._2FA_DEVICE && res.devices) {
    // Select default device
    availableDevices.value = res.devices
    select2FADevice(res.devices[0]?.id)
  }

  // configure UI for 2FA challenge
  if (res.nextStep === LoginResponse.next_step._2FA_TOKEN) {
    deviceId.value = res.device?.id
    // nextTick(() => authCodeRef.value?.focus())
  }

  currentStep.value = res.nextStep
}

const select2FADevice = (id: string) => {
  deviceId.value = id
  authCode.value = ''
  login(true)
  deviceSelectMode.value = false
}

onMounted(() => {
  if (auth.isLoggedIn) redirect()

  // emailRef.value?.focus()
})
</script>

<style lang="scss" scoped>
.login-view-container {
  padding-top: 25vh;
}
</style>
