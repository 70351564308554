/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type ChangedFilter = {
  invert?: boolean;
  value: (boolean | null);
  type?: ChangedFilter.type;
};
export namespace ChangedFilter {
  export enum type {
    CHANGED = 'changed',
  }
}

