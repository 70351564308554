<template>
  <div class="settings-view-container">
    <AppBarBreadcrumb>{{ $t('settings.header') }}</AppBarBreadcrumb>

    <TabbedView :tab-items="tabItems" />
  </div>
</template>

<script setup lang="ts">
import AppBarBreadcrumb from '@/components/common/AppBarBreadcrumb.vue'
import TabbedView from '@/components/TabbedView.vue'
import { canUserSeeDebugFeatures } from '@/plugins/posthog'
import { useAuthStore } from '@/store'
import { useI18n } from 'vue-i18n'
import type { TTabItem } from '@/components/TabbedView.vue'

const auth = useAuthStore()
const i18n = useI18n()

const tabItems: Array<TTabItem> = [
  {
    label: i18n.t('settings.account.header'),
    icon: 'far fa-user',
    routeName: 'settings_basic',
  },
  ...(canUserSeeDebugFeatures.value
    ? [
        {
          label: i18n.t('settings.themes.themes'),
          icon: 'far fa-paintbrush',
          routeName: 'settings_theme',
        } as TTabItem,
      ]
    : []),
  {
    label: i18n.t('settings.api.header'),
    icon: 'far fa-circle-nodes',
    routeName: 'settings_api',
  },
  {
    label: i18n.t('settings.security.header'),
    icon: 'far fa-shield-halved',
    routeName: 'settings_security',
  },
  {
    label: i18n.t('settings.integrations.header'),
    icon: 'far fa-plug',
    routeName: 'settings_integrations',
  },
]

if (auth.hasOrganization) {
  tabItems.splice(1, 0, {
    label: i18n.t('settings.organization.header'),
    icon: 'far fa-building',
    routeName: 'settings_organization',
  })
}
</script>
