/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type TopicUpdateRequest = {
  /**
   * Unique identifier for this topic.
   */
  id: string;
  /**
   * Sentiment with regards to this topic. If sentiment_enabled is false for the topic, sentiment must be `any`.
   */
  sentiment: TopicUpdateRequest.sentiment;
};
export namespace TopicUpdateRequest {
  /**
   * Sentiment with regards to this topic. If sentiment_enabled is false for the topic, sentiment must be `any`.
   */
  export enum sentiment {
    NEUTRAL = 'neutral',
    POSITIVE = 'positive',
    NEGATIVE = 'negative',
    ANY = 'any',
  }
}

