/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AnonymizePIIResponse } from './AnonymizePIIResponse';
import type { AuxiliaryFieldResponse } from './AuxiliaryFieldResponse';
import type { TextToAnalyzeFieldResponse } from './TextToAnalyzeFieldResponse';
export type ProjectDetailResponse = {
  /**
   * Unique identifier for the project.
   */
  id: string;
  /**
   * Name of this project.
   */
  name: string;
  /**
   * Identifier of the user that owns this project.
   */
  owner: string;
  /**
   * Tags associated with this project.
   */
  tags: Array<string>;
  /**
   * Current upload status of this project.
   */
  upload_status: ProjectDetailResponse.upload_status;
  /**
   * Base language for this project.
   */
  language: ProjectDetailResponse.language;
  /**
   * Current translation status for this project.
   */
  translation_status?: ProjectDetailResponse.translation_status;
  /**
   * Translation engine used for translating `text_to_analyze` columns.
   */
  translation_engine?: ProjectDetailResponse.translation_engine;
  /**
   * Timestamp at which the project was created.
   */
  created: string;
  /**
   * Timestamp at which the project was last updated.
   */
  last_modified: string;
  /**
   * If set, all values of text_to_analyze columns are anonymized.
   */
  anonymize_pii?: AnonymizePIIResponse;
  columns: Array<(TextToAnalyzeFieldResponse | AuxiliaryFieldResponse)>;
};
export namespace ProjectDetailResponse {
  /**
   * Current upload status of this project.
   */
  export enum upload_status {
    PENDING = 'pending',
    IN_PROGRESS = 'in_progress',
    SUCCEEDED = 'succeeded',
    FAILED = 'failed',
  }
  /**
   * Base language for this project.
   */
  export enum language {
    AF = 'af',
    SQ = 'sq',
    EU = 'eu',
    CA = 'ca',
    CS = 'cs',
    DA = 'da',
    NL = 'nl',
    EN = 'en',
    ET = 'et',
    FI = 'fi',
    FR = 'fr',
    GL = 'gl',
    DE = 'de',
    EL = 'el',
    HU = 'hu',
    IS = 'is',
    IT = 'it',
    LB = 'lb',
    LT = 'lt',
    LV = 'lv',
    MK = 'mk',
    NO = 'no',
    PL = 'pl',
    PT = 'pt',
    RO = 'ro',
    SR = 'sr',
    SK = 'sk',
    SL = 'sl',
    ES = 'es',
    SV = 'sv',
    TR = 'tr',
  }
  /**
   * Current translation status for this project.
   */
  export enum translation_status {
    PENDING = 'pending',
    SUCCEEDED = 'succeeded',
    FAILED = 'failed',
  }
  /**
   * Translation engine used for translating `text_to_analyze` columns.
   */
  export enum translation_engine {
    GOOGLE_TRANSLATE = 'google_translate',
    DEEPL = 'deepl',
  }
}

