<!-- We need this component to manage inner value binding with a debounced prop value updating -->
<template>
  <NInput
    ref="inputRef"
    :value="innerValue"
    v-bind="$attrs"
    @clear="handleInputUpdate()"
    @input="handleInputUpdate($event)"
  >
    <!-- Pass down all slots to support NInput slots with DebouncedInput -->
    <template v-for="(_, name) in $slots" #[name]="slotProps">
      <slot v-if="slotProps" :name="name" v-bind="slotProps" />
      <slot v-else :name="name" />
    </template>
  </NInput>
</template>

<script lang="ts" setup>
import { ref } from 'vue'
import { useDebounceFn } from '@vueuse/core'
import { watch } from 'vue'
import type { InputInst, InputProps } from 'naive-ui'

interface DebouncedInputProps extends /* @vue-ignore */ InputProps {
  value: string | undefined
  debounceTime?: number
}

defineOptions({
  inheritAttrs: false,
})
const props = withDefaults(defineProps<DebouncedInputProps>(), {
  value: '',
  debounceTime: 400,
})
const emit = defineEmits<{
  'update:value': [value: string | undefined]
}>()

const innerValue = ref(props.value)
const inputRef = ref<InputInst | null>(null)

watch(
  () => props.value,
  (newValue) => {
    // bind inner value to the prop value
    innerValue.value = newValue
  }
)

const debouncedUpdateValue = useDebounceFn((value: string | undefined) => {
  // update prop with a debounced function
  emit('update:value', value)
}, props.debounceTime)

const handleInputUpdate = (value?: string) => {
  // update inner value immediately not to loose reactivity
  innerValue.value = value || ''
  debouncedUpdateValue(value)
}

defineExpose({
  inputRef,
})
</script>
