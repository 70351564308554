/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ColumnSemanticTypes } from './ColumnSemanticTypes';
export type StringColumn = {
  name: string;
  semantic_type?: ColumnSemanticTypes;
  ref?: string;
  type: StringColumn.type;
  hide_for_filtering?: boolean;
  vals?: (any[] | null);
  few?: boolean;
};
export namespace StringColumn {
  export enum type {
    STRING = 'string',
  }
}

