/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { FilterKind } from './FilterKind';
import type { GenericFilterResponse } from './GenericFilterResponse';
import type { StarBarChartWidgetResponse } from './StarBarChartWidgetResponse';
import type { StarBreakdownWidgetResponse } from './StarBreakdownWidgetResponse';
import type { StarLineChartWidgetResponse } from './StarLineChartWidgetResponse';
import type { StarSegmentedBarChartWidgetResponse } from './StarSegmentedBarChartWidgetResponse';
export type StarRatingInsightElementResponse = {
  id: string;
  kind?: 'StarRatingInsight';
  section_id: string;
  order_id: number;
  created: string;
  last_modified: string;
  filters: Array<GenericFilterResponse>;
  available_filters: Record<string, Array<FilterKind>>;
  star_breakdown: StarBreakdownWidgetResponse;
  star_segmented_bar?: StarSegmentedBarChartWidgetResponse;
  star_over_time_line: StarLineChartWidgetResponse;
  star_over_time_bar: StarBarChartWidgetResponse;
  visible_left: StarRatingInsightElementResponse.visible_left;
  visible_right: StarRatingInsightElementResponse.visible_right;
};
export namespace StarRatingInsightElementResponse {
  export enum visible_left {
    STAR_BREAKDOWN = 'star_breakdown',
    STAR_SEGMENTED_BAR = 'star_segmented_bar',
  }
  export enum visible_right {
    STAR_OVER_TIME_LINE = 'star_over_time_line',
    STAR_OVER_TIME_BAR = 'star_over_time_bar',
  }
}

