/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type TopicerParametersReduce = {
  kind?: TopicerParametersReduce.kind;
};
export namespace TopicerParametersReduce {
  export enum kind {
    REDUCE = 'reduce',
  }
}

