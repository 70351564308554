import { colord, getFormat } from 'colord'
import { colors } from '@unocss/preset-mini'

export type TColorNames = keyof typeof colors

const {
  rose,
  pink,
  fuchsia,
  purple,
  violet,
  indigo,
  blue,
  sky,
  cyan,
  teal,
  emerald,
  green,
  lime,
  yellow,
  amber,
  orange,
  red,
} = colors

const baseColors = [
  rose[500],
  pink[500],
  fuchsia[500],
  purple[500],
  violet[500],
  indigo[500],
  blue[500],
  sky[500],
  cyan[500],
  teal[500],
  emerald[500],
  green[500],
  lime[500],
  yellow[500],
  amber[500],
  orange[500],
  red[500],
]

export function stringToColor(val: string) {
  let hash = 0

  // create a hash from string to use as color index, this will give same output for the same text
  for (let i = 0; i < val.length; i++) {
    hash = val.charCodeAt(i) + ((hash << 5) - hash)
    hash = hash & hash
  }

  const colorIndex = Math.abs(hash) % baseColors.length

  // return ready to use hex color code
  return baseColors[colorIndex]
}

export const isColorName = (val: string): val is keyof typeof colors => val in colors

export const getTagColor = (color: string) => {
  if (!color) return undefined
  const format = getFormat(color)
  let parsedColor = ''

  if (format) parsedColor = color
  else if (!format && isColorName(color)) parsedColor = colors[color][500] as string
  else {
    parsedColor = '#dedede'
    console.warn('[ERROR]: Defined color could not be set, setting #dedede as default!')
  }

  return { color: colord(parsedColor).alpha(0.1).toHex(), textColor: parsedColor }
}

export const getTagColorFromString = (val: string) => getTagColor(stringToColor(val))
