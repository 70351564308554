/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type DummyUndesirable = {
  string_scalar?: DummyUndesirable.string_scalar;
  enum_like: DummyUndesirable.enum_like;
};
export namespace DummyUndesirable {
  export enum string_scalar {
    REPORT_SUMMARY_INSIGHT = 'ReportSummaryInsight',
  }
  export enum enum_like {
    REPORT_SUMMARY_INSIGHT = 'ReportSummaryInsight',
    NPSSCORE_INSIGHT = 'NPSScoreInsight',
  }
}

