import { ApiError, getErrorMsg } from '@/api'
import { MutationCache, QueryCache, QueryClient, VueQueryPlugin } from '@tanstack/vue-query'
import { useAuthStore } from '@/store'
import type { VueQueryPluginOptions } from '@tanstack/vue-query'

const handleAuthError = async (err: ApiError) => {
  const auth = useAuthStore()

  // if user is logged in and got an access_denied error, this means that the user is logged out
  if (auth.isLoggedIn && err.body?.code === 'access_denied') {
    await auth.logout()
  }
}

// Global error handlers
const queryCache = new QueryCache({
  // Potential custom error handling for later
  // onError: (result, query) => {
  //   const error = result instanceof ApiError ? (result as ApiError) : (result as AxiosError).meta
  // },
  onError: async (result: unknown) => {
    if (result instanceof ApiError) {
      if (result.status === 403) {
        await handleAuthError(result)
      } else {
        // generic error handler for useQuery functions, migrated here after v5 (further reading: https://tkdodo.eu/blog/breaking-react-querys-api-on-purpose)
        window.$message.error(getErrorMsg(result))
      }
    }
  },
})

const mutationCache = new MutationCache({
  onError: async (result: unknown) => {
    if (result instanceof ApiError && result.status === 403) await handleAuthError(result)
  },
})

// custom query client to be able to access outside of vue components
export const queryClient = new QueryClient({
  queryCache,
  mutationCache,
})

const vueQueryPluginOptions: VueQueryPluginOptions = {
  queryClient,
  enableDevtoolsV6Plugin: true,
}

export { VueQueryPlugin, vueQueryPluginOptions }
