/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { TopicColumnResponse } from './TopicColumnResponse';
export type TextToAnalyzeColumnResponse = {
  /**
   * Human-readable identifier for this column.
   */
  ref: string;
  /**
   * Type of this column.
   */
  type: TextToAnalyzeColumnResponse.type;
  /**
   * Text value assigned to this column.
   */
  value: string;
  /**
   * Specifies whether the topics for this text to analyze column have already been reviewed. If `was_reviewed=true`, Caplena will consider the topics to be correctly assigned and will not change the topic assignment anymore (unless manually changed by a user or via API). Caplena will use this information to improve the AI.
   */
  was_reviewed?: boolean;
  /**
   * Inferred overall sentiment for this value.
   */
  sentiment_overall?: TextToAnalyzeColumnResponse.sentiment_overall;
  /**
   * Source language of this value.
   */
  source_language?: string;
  /**
   * Translated value if translation is enabled for this column.
   */
  translated_value?: string;
  /**
   * Topics matching the value of this column. If no topics match, an empty array is returned.
   */
  topics: Array<TopicColumnResponse>;
};
export namespace TextToAnalyzeColumnResponse {
  /**
   * Type of this column.
   */
  export enum type {
    TEXT_TO_ANALYZE = 'text_to_analyze',
  }
  /**
   * Inferred overall sentiment for this value.
   */
  export enum sentiment_overall {
    NEUTRAL = 'neutral',
    POSITIVE = 'positive',
    NEGATIVE = 'negative',
  }
}

