/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { FilterKind } from './FilterKind';
import type { GenericFilterResponse } from './GenericFilterResponse';
import type { NpsScoreGaugeChartWidgetResponse } from './NpsScoreGaugeChartWidgetResponse';
import type { NpsScorePieChartWidgetResponse } from './NpsScorePieChartWidgetResponse';
import type { NpsScoreSegmentedBarChartWidgetResponse } from './NpsScoreSegmentedBarChartWidgetResponse';
import type { TextWidgetResponse } from './TextWidgetResponse';
import type { TtaColumnRowsWidgetResponse } from './TtaColumnRowsWidgetResponse';
export type NpsScoreWoTimeInsightElementResponse = {
  id: string;
  kind?: 'NPSScoreWoTimeInsight';
  section_id: string;
  order_id: number;
  created: string;
  last_modified: string;
  filters: Array<GenericFilterResponse>;
  available_filters: Record<string, Array<FilterKind>>;
  nps_score_gauge: NpsScoreGaugeChartWidgetResponse;
  nps_score_pie: NpsScorePieChartWidgetResponse;
  nps_score_segmented_bar?: NpsScoreSegmentedBarChartWidgetResponse;
  promoters_rows: TtaColumnRowsWidgetResponse;
  promoters_summary: TextWidgetResponse;
  detractors_rows: TtaColumnRowsWidgetResponse;
  detractors_summary: TextWidgetResponse;
  visible_left: NpsScoreWoTimeInsightElementResponse.visible_left;
  visible_center: NpsScoreWoTimeInsightElementResponse.visible_center;
  visible_right: NpsScoreWoTimeInsightElementResponse.visible_right;
};
export namespace NpsScoreWoTimeInsightElementResponse {
  export enum visible_left {
    NPS_SCORE_GAUGE = 'nps_score_gauge',
    NPS_SCORE_PIE = 'nps_score_pie',
    NPS_SCORE_SEGMENTED_BAR = 'nps_score_segmented_bar',
  }
  export enum visible_center {
    PROMOTERS_ROWS = 'promoters_rows',
    PROMOTERS_SUMMARY = 'promoters_summary',
  }
  export enum visible_right {
    DETRACTORS_ROWS = 'detractors_rows',
    DETRACTORS_SUMMARY = 'detractors_summary',
  }
}

