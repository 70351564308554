/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { RowTopic } from './RowTopic';
export type ProjectCodingRowTextUIResponse = {
  ref: string;
  value: string;
  was_reviewed: boolean;
  sentiment_overall?: ProjectCodingRowTextUIResponse.sentiment_overall;
  source_language?: string;
  translated_value?: string;
  highlight?: Array<number>;
  duplicates_count?: number;
  topics: Array<RowTopic>;
};
export namespace ProjectCodingRowTextUIResponse {
  export enum sentiment_overall {
    NEUTRAL = 'neutral',
    POSITIVE = 'positive',
    NEGATIVE = 'negative',
  }
}

