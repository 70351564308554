/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ColumnSemanticTypes } from './ColumnSemanticTypes';
export type NullColumn = {
  name: string;
  semantic_type?: ColumnSemanticTypes;
  ref?: string;
  type: NullColumn.type;
  hide_for_filtering?: boolean;
};
export namespace NullColumn {
  export enum type {
    NULL = 'null',
  }
}

