<template>
  <NSpin v-if="isLoading" :size="16" show />
  <!-- TO DO: translations -->
  <NSelect
    v-else
    :value="modelValue"
    :options="mappedRoleList"
    filterable
    placeholder="Select a Role"
    :fallback-option="false"
    :render-label="renderLabel"
    :render-tag="renderTag"
    @update:value="(val: number) => $emit('update:value', val)"
  />
</template>

<script setup lang="ts">
import { computed, h } from 'vue'
import { getRoleTranslation } from '@/utils'
import { renderIconButton, renderTooltip } from '@/utils'
import { useOrganizationRoleListQuery } from '@/api/vq/organization'
import type { SelectOption, SelectRenderTag } from 'naive-ui'

type TMappedOrgRole = SelectOption & {
  label: string
  description: string
}

defineProps<{
  modelValue?: number
}>()

defineEmits<{
  'update:value': [value: number]
}>()

const { data, isLoading } = useOrganizationRoleListQuery()

const mappedRoleList = computed<TMappedOrgRole[]>(() => {
  if (!data.value) return []

  return data.value.map((role) => {
    const translatedRole = getRoleTranslation(role.name)

    return {
      label: translatedRole.name,
      description: translatedRole.description,
      value: role.id,
      disabled: !role.applicable,
    }
  })
})

const renderLabel = (option: TMappedOrgRole) => {
  return h('div', { class: 'flex w-full' }, [
    renderTooltip(renderIconButton('fa-circle-info'), option.description),
    h('div', { class: 'mx-2' }, option.label),
  ])
}

const renderTag: SelectRenderTag = ({ option }) => {
  return h('span', {}, option.label as string)
}
</script>

<style lang="scss" scoped></style>
