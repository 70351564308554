import { ref } from 'vue'
import type { TopicUIResponse } from '@/api'

export type TDragMode = 'topic' | 'category' | 'keyword'
export type TKeywordMove = { keyword: string; idx: number }

// objects
const srcTopic = ref<TopicUIResponse | undefined>()
const srcCategory = ref<TCategory | undefined>()
const srcKeyword = ref<TKeywordMove | undefined>()

// html elements
const srcDraggingEl = ref<HTMLElement | undefined>()
const srcParentCategoryEl = ref<HTMLElement | undefined>()
const srcParentBackgroundEl = ref<HTMLElement | undefined>()

// utility
const mode = ref<TDragMode | undefined>()
const isDragging = ref(false)

const onDragStart = (e: DragEvent, initiator: TDragMode, src: TopicUIResponse | TCategory, keyword?: TKeywordMove) => {
  // set utility values
  isDragging.value = true
  mode.value = initiator

  // save source item
  if (initiator === 'topic' || initiator === 'keyword') {
    srcTopic.value = src as TopicUIResponse
    srcKeyword.value = keyword
  } else if (initiator === 'category') srcCategory.value = src as TCategory

  // save the element that is being dragged
  srcDraggingEl.value = e.target as HTMLElement
  srcDraggingEl.value.style.opacity = '0.4'

  // save the parent elements of the dragging element
  srcParentCategoryEl.value = srcDraggingEl.value.closest('[data-dropzone="category"]') as HTMLElement
  srcParentBackgroundEl.value = srcDraggingEl.value.closest('[data-dropzone="background"]') as HTMLElement

  // firefox needs to have something to bite here to initiate drag
  e.dataTransfer?.setData('text', 'anything')
}

const onDragEnterTopic = (e: DragEvent) => {
  const target = e.target as HTMLElement

  if (srcDraggingEl.value === target || srcDraggingEl.value?.contains(target) || mode.value === 'category') return

  target.classList.add('dragging-over')
}

const onDragLeaveTopic = (e: DragEvent) => {
  const target = e.target as HTMLElement

  if (srcDraggingEl.value === target || srcDraggingEl.value?.contains(target) || mode.value === 'category') return

  target.classList.remove('dragging-over')
}

const onDragEnterCategory = (e: DragEvent) => {
  const target = e.target as HTMLElement

  if (
    target === srcParentCategoryEl.value ||
    srcParentCategoryEl.value?.contains(target) ||
    target.getAttribute('data-dropzone') === 'topic' ||
    mode.value === 'keyword'
  )
    return
  target.classList.add('dragging-over')
}

const onDragLeaveCategory = (e: DragEvent) => {
  const target = e.target as HTMLElement

  if (
    target === srcParentCategoryEl.value ||
    srcParentCategoryEl.value?.contains(target) ||
    target.getAttribute('data-dropzone') === 'topic' ||
    mode.value === 'keyword'
  )
    return

  target.classList.remove('dragging-over')
}

const onDragEnterBackground = (e: DragEvent) => {
  const target = e.target as HTMLElement

  if (mode.value !== 'category') return
  if (srcParentCategoryEl.value?.contains(target)) return

  target.classList.add('dragging-over')
}

const onDragLeaveBackground = (e: DragEvent) => {
  const target = e.target as HTMLElement

  target.classList.remove('dragging-over')
}

const onDragEnd = () => {
  // set utility to undefined
  isDragging.value = false
  mode.value = undefined

  // set html elements to undefined
  if (srcDraggingEl.value) {
    srcDraggingEl.value.style.opacity = ''
    srcDraggingEl.value = undefined
  }
  srcParentCategoryEl.value = undefined
  srcParentBackgroundEl.value = undefined
}

export const useDraggable = () => {
  // prevent default dragover event
  document.addEventListener('dragover', (e) => e.preventDefault(), false)

  return {
    mode,
    isDragging,
    srcTopic,
    srcKeyword,
    srcCategory,
    srcDraggingEl,
    srcParentCategoryEl,

    onDragStart,
    onDragEnd,

    onDragEnterTopic,
    onDragLeaveTopic,

    onDragEnterCategory,
    onDragLeaveCategory,

    onDragEnterBackground,
    onDragLeaveBackground,
  }
}
