/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ColumnSemanticTypes } from './ColumnSemanticTypes';
export type RangeColumn = {
  name: string;
  semantic_type?: ColumnSemanticTypes;
  ref?: string;
  type: RangeColumn.type;
  hide_for_filtering?: boolean;
  min?: (number | null);
  max?: (number | null);
  has_nan?: boolean;
};
export namespace RangeColumn {
  export enum type {
    NUMBER = 'number',
  }
}

