/* eslint-disable @typescript-eslint/no-explicit-any */
import isPostalCode from 'validator/es/lib/isPostalCode.js'
import { addMethod, string } from 'yup'
import { i18n } from '@/plugins/i18n'

// add postal code rule to strings
addMethod(string, 'postalCode', function postalCode() {
  return this.test('postal-code', function testFunction(value) {
    if (!value) return true

    return (
      isPostalCode(value, 'any') ||
      this.createError({ path: this.path, message: i18n.global.t('formValidation.postCode') })
    )
  })
})

/* commonly used rules in the application */
export const phoneNumberRule = string()
  .min(9)
  .matches(/^\+\d{5,15}$/, i18n.global.t('formValidation.phoneNumber'))
export const passwordRule = string().required().min(6)
export const nameRule = string().required().min(3)
export const authCodeRule = string().required().length(6)
