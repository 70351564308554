<template>
  <NIcon v-if="iconSVG" :size="size" v-bind="$attrs">
    <i v-html="iconSVG" />
  </NIcon>
</template>

<script lang="ts">
import library from '@/plugins/svg-icons'
import { computed, defineComponent } from 'vue'
import type { IconProps } from 'naive-ui'
import type { PropType } from 'vue'

const CustomIconComponent = defineComponent({
  name: 'CustomIcon',
  inheritAttrs: false,
  props: {
    icon: {
      type: String as PropType<TCustomIconName>,
      required: true,
    },
    size: {
      type: [Number, String],
      default: 18,
    },
  },
  setup(props) {
    const iconSVG = computed(() => library[props.icon])

    return { iconSVG }
  },
})

// TO DO: find out if this is possible to do in setup syntax.
// leaving it as is unless we find a solution
export default CustomIconComponent as typeof CustomIconComponent & IconProps
</script>

<style lang="scss" scoped></style>
