/* eslint-disable @typescript-eslint/no-explicit-any */
import DropdownFilter from './DropdownFilter.vue'
import PanelFilter from './PanelFilter.vue'
import SelectedFilters from './SelectedFilters.vue'
import type { Component, VNode } from 'vue'
import type { TIcon } from '@/plugins/font-awesome'

export type TFilterValue = Array<string | number> | string | undefined
export type TPossibleFilterItemType = string | number | Record<string, unknown>
type TRenderTagContent = (filterValue: Array<string | number> | string) => VNode | string
export enum EFilterType {
  Checkbox = 'checkbox',
  Date = 'date',
  Range = 'range',
  SearchInput = 'search-input',
  Custom = 'custom',
}

// Base filter to be used for each type. The attributes here are shared and used in each filter type
type TBaseFilterType = {
  /** Key of the filter object e.g. myFilter[KEY]. This is especially important when mapping or returning filter values. */
  key: string
  /** Name of the filter you want to present it with. This will be the name for the filter. */
  label: string
  /** Label of the value to show. */
  valueLabel?: string
  /** FontAwesome icon name for filter items. Expected an icon name from FontAwesome  */
  icon?: TIcon
  /** Optional loaidng state for asynchrounously added filter values */
  loading?: boolean
  /** Render function for tag content. If you need to customize content of the tag. Takes a single param which is current filter value array, and expects a `vue component` in return */
  renderTagContent?: TRenderTagContent
}

export type TCheckboxFilter<T = TPossibleFilterItemType> = TBaseFilterType & {
  type: EFilterType.Checkbox
  /** Array for available filter items. Expected a string/number array or an object array. Make sure to use itemValueKey & searchItemKey when using object arrays  */
  items: Array<T> | undefined
  /** Selected values for the filter. This is strictly required since filter works depending on this value. You could bind this value to another observable value depending on your use case */
  value: Array<string> | string | undefined
  /** **itemValueKey:** if your `items` array is an object array, you would need to specify which object property is used for value  */
  itemValueKey?: string
  /** **itemTextKey:** if your `items` array is an object array, you would need to specify which object property is used for presentation text  */
  itemTextKey?: string
  /** **searchItemKey:** if your `items` array is an object array, you would need to specify which object property is used for search  */
  searchItemKey?: string
  /** Allows/disallows multiselecting checkboxes, default is true  */
  multiple?: boolean
  /** Allows/disallows search input, default is true  */
  searchable?: boolean
  /** Placeholder value for search input in checkbox list */
  searchPlaceholder?: string
  /** Render function for checkbox content. If you need to customize content of the checkbox. Takes a single param which is your filter value (or object), and expects a `vue component` in return */
  renderCheckboxContent?: (filter: any) => VNode | string
}

export type TDateFilter = TBaseFilterType & {
  type: EFilterType.Date
  /** Selected values for the filter. This is strictly required since filter works depending on this value. You could bind this value to another observable value depending on your use case */
  value: Array<number> | undefined
}

export type TRangeFilter = TBaseFilterType & {
  type: EFilterType.Range
  /** Selected values for the filter. This is strictly required since filter works depending on this value. You could bind this value to another observable value depending on your use case */
  value: Array<number> | undefined
  minimum: number
  maximum: number
}

export type TSearchInputFilter = TBaseFilterType & {
  type: EFilterType.SearchInput
  /** Selected values for the filter. This is strictly required since filter works depending on this value. You could bind this value to another observable value depending on your use case */
  value: string | undefined
}

export type TCustomFilter = TBaseFilterType & {
  type: EFilterType.Custom
  value: string | Array<string | number> | undefined
  component: Component
  props: Record<string, any>
}

// possible filter type can be overwritten if you have non-mixed filter items.
// for mixed filter types, not overwriting is recommended
export type TFilters<T = TPossibleFilterItemType> = TFilterConfig<T>[]
export type TFilterConfig<T = TPossibleFilterItemType> =
  | TCheckboxFilter<T>
  | TDateFilter
  | TRangeFilter
  | TSearchInputFilter
  | TCustomFilter

// null represents an empty filter while undefined means the filter is not set
export type TPossibleFilterValue = string | number | Array<string | number> | boolean | undefined | null

export { DropdownFilter }
export { PanelFilter }
export { SelectedFilters }
