/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type UpdateRawTopicCollectionStatusResponse = {
  status: UpdateRawTopicCollectionStatusResponse.status;
};
export namespace UpdateRawTopicCollectionStatusResponse {
  export enum status {
    PENDING = 'pending',
    IN_PROGRESS = 'in_progress',
    SUCCEEDED = 'succeeded',
    FAILED = 'failed',
  }
}

