export function downloadFileFromLink(url: string, filename: string) {
  // Create a temporary anchor element
  const link = document.createElement('a')

  // Set the href to the signed URL
  link.href = url
  // Set the download attribute to the desired filename (or a default name)
  link.download = filename // Replace with your desired filename and extension
  // Append the link to the body
  document.body.appendChild(link)
  // Simulate a click on the link to start the download
  link.click()
  // Remove the link from the body
  document.body.removeChild(link)
}
