<template>
  <div class="search-input">
    <DebouncedInput
      ref="debouncedInputRef"
      class="search-input__input"
      :class="inputClass"
      v-bind="inputAttrs"
      :value="value"
      :placeholder="placeholder ? placeholder : $t('common.search')"
      :debounce-time="debounceTime"
      clearable
      @blur="handleCollapse"
      @update:value="(val: string) => $emit('update:value', val)"
    >
      <template #prefix>
        <NIcon>
          <FaIcon icon="fa-search" size="xs" />
        </NIcon>
      </template>
    </DebouncedInput>

    <!-- icon to collapse input -->
    <NButton
      v-if="collapsable"
      v-bind="iconButtonAttrs"
      class="search-input__icon"
      type="info"
      quaternary
      icon
      @click="handleExpand"
    >
      <template #icon>
        <NIcon>
          <FaIcon icon="fa-search" size="sm" />
        </NIcon>
      </template>
    </NButton>
  </div>
</template>

<script lang="ts">
import DebouncedInput from './DebouncedInput.vue'
import { type ButtonProps, NInput } from 'naive-ui'
import { computed, defineComponent, ref } from 'vue'
import { watch } from 'vue'

const SearchInput = defineComponent({
  name: 'SearchInput',
  components: {
    DebouncedInput,
  },
  inheritAttrs: false,
  props: {
    value: {
      type: String,
    },
    width: {
      type: Number,
      default: 220,
    },
    block: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
    },
    collapsable: {
      type: Boolean,
      default: false,
    },
    debounceTime: {
      type: Number,
      default: 0,
    },
    circle: {
      type: Boolean,
      default: false,
    },
    inputClass: {
      type: String,
      default: '',
    },
  },
  emits: ['update:value'],
  setup(props, ctx) {
    const debouncedInputRef = ref()
    const collapsed = ref(true)

    const inputAttrs = computed(() => ({
      ...ctx.attrs,
      style: {
        overflow: 'hidden',
        opacity: collapsed.value ? 0 : undefined,
        width: collapsed.value ? '0px' : props.block ? undefined : `${props.width}px`,
      },
    }))

    const iconButtonAttrs = computed(() => ({
      circle: props.circle ? true : false,
      size: ctx.attrs.size ? (ctx.attrs.size as ButtonProps['size']) : 'medium',
      style: {
        overflow: 'hidden',
        opacity: collapsed.value ? 1 : 0,
        maxWidth: collapsed.value ? '70px' : 0,
        padding: collapsed.value ? undefined : 0,
      },
    }))

    const handleCollapse = () => {
      if (!props.collapsable) return

      // if the actual input element has not any value -> collapse
      if (!debouncedInputRef.value?.inputRef.inputElRef.value) {
        collapsed.value = true
      }
    }

    const handleExpand = () => {
      collapsed.value = false
      debouncedInputRef.value?.inputRef?.focus()
    }

    // if collapsable prop changes, collapse/expand input
    watch(
      () => props.collapsable,
      (newVal) => {
        collapsed.value = newVal
      },
      { immediate: true }
    )

    return { debouncedInputRef, inputAttrs, iconButtonAttrs, handleCollapse, handleExpand }
  },
})

// TO DO: find out if this is possible to do in setup syntax.
// leaving it as is unless we find a solution
export default SearchInput as typeof SearchInput & typeof NInput
</script>

<style lang="scss" scoped>
.search-input {
  display: flex;
  align-items: center;

  &__input,
  &__icon {
    transition: all var(--default-transition);
  }
}
</style>
