import { AccountService, ApiError, getErrorMsg } from '@/api'
import { queryClient } from '@/plugins/vue-query'
import { useMutation, useQuery } from '@tanstack/vue-query'
import type {
  OrganizationBillingResponse,
  OrganizationDetailResponse,
  PaginatedTeamMemberResponse,
  RoleResponse,
  TeamMemberListUIConfigResponse,
  TeamMemberResponse,
} from '@/api'

// All method keys in AccountService
type TAccountServiceMethodKeys = ClassMethodKeys<typeof AccountService>
// Parameter types extractor
export type ArgTypes<K extends TAccountServiceMethodKeys> = ClassMethodParamType<typeof AccountService, K>

/***********/
/* QUERIES */
/***********/

export function useOrganizationDetailQuery(options: TCustomUseQueryOptions<OrganizationDetailResponse> = {}) {
  return useQuery({ queryKey: ['organization'], queryFn: () => AccountService.organizationDetail(), ...options })
}

export function useOrganizationRoleListQuery(options: TCustomUseQueryOptions<RoleResponse[]> = {}) {
  return useQuery({
    queryKey: ['organization-role-list'],
    queryFn: () => AccountService.organizationRoleList(),
    ...options,
  })
}

export function useTeamMemberListQuery<T = PaginatedTeamMemberResponse>(
  params: ArgTypes<'teamMemberList'> = {},
  options: TCustomUseQueryOptions<PaginatedTeamMemberResponse, T> = {}
) {
  return useQuery({ queryKey: ['team-members'], queryFn: () => AccountService.teamMemberList(params), ...options })
}

export function useTeamMemberListConfigQuery(options: TCustomUseQueryOptions<TeamMemberListUIConfigResponse> = {}) {
  return useQuery({
    queryKey: ['team-members-config'],
    queryFn: () => AccountService.teamMemberListConfig(),
    ...options,
  })
}

/*************/
/* MUTATIONS */
/*************/

type TOrganizationUpdateMutationOptions = TCustomUseMutationOptions<
  OrganizationDetailResponse,
  ArgTypes<'organizationUpdate'>
>
export function useOrganizationUpdateMutation(options: TOrganizationUpdateMutationOptions = {}) {
  return useMutation({
    mutationFn: (mutationParams) => AccountService.organizationUpdate(mutationParams),
    onError: (err: ApiError) => window.$message.error(getErrorMsg(err)),
    ...options,
    // onSuccess should not be overwritten
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['organization'] })
    },
  })
}

type TOrganizationBillingUpdateMutationOptions = TCustomUseMutationOptions<
  OrganizationBillingResponse,
  ArgTypes<'organizationBillingUpdate'>
>

export function useOrganizationBillingUpdateMutation(options: TOrganizationBillingUpdateMutationOptions = {}) {
  return useMutation({
    mutationFn: (mutationParams) => AccountService.organizationBillingUpdate(mutationParams),
    onError: (err: ApiError) => window.$message.error(getErrorMsg(err)),
    ...options,
    // onSuccess should not be overwritten
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['organization'] })
    },
  })
}

type TTeamMemberDeleteMutation = TCustomUseMutationOptions<void, ArgTypes<'teamMemberDelete'>>
export function useTeamMemberDeleteMutation(options: TTeamMemberDeleteMutation = {}) {
  return useMutation({
    mutationFn: (mutationParams) => AccountService.teamMemberDelete(mutationParams),
    onError: (err: ApiError) => window.$message.error(getErrorMsg(err)),
    ...options,
    // onSuccess should not be overwritten
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['team-members'] })
    },
  })
}

type TTeamMemberCreateMutation = TCustomUseMutationOptions<TeamMemberResponse, ArgTypes<'teamMemberCreate'>>
export function useTeamMemberCreateMutation(options: TTeamMemberCreateMutation = {}) {
  return useMutation({
    mutationFn: (mutationParams) => AccountService.teamMemberCreate(mutationParams),
    onError: (err: ApiError) => window.$message.error(getErrorMsg(err)),
    ...options,
    // onSuccess should not be overwritten
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['team-members'] })
    },
  })
}

type TTeamMemberUpdateMutation = TCustomUseMutationOptions<TeamMemberResponse, ArgTypes<'teamMemberUpdate'>>
export function useTeamMemberUpdateMutation(options: TTeamMemberUpdateMutation = {}) {
  return useMutation({
    mutationFn: (mutationParams) => AccountService.teamMemberUpdate(mutationParams),
    onError: (err: ApiError) => window.$message.error(getErrorMsg(err)),
    ...options,
    // onSuccess should not be overwritten
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['team-members'] })
    },
  })
}
