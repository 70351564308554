/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type TopicerParametersMore = {
  kind?: TopicerParametersMore.kind;
  within_category?: string;
};
export namespace TopicerParametersMore {
  export enum kind {
    MORE = 'more',
  }
}

