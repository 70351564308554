import en from '@/assets/locales/en.json'
import { API } from '@/api'
import { computed, nextTick } from 'vue'
import { createI18n } from 'vue-i18n'
import { dateDeDE, dateEnGB, deDE } from 'naive-ui'
import { formatDistanceToNow } from 'date-fns'
import { merge } from 'lodash-es'
import { setLocale } from 'yup'
import { useAuthStore } from '@/store'
import type { I18nOptions } from 'vue-i18n'

export type TMessageSchema = typeof en

const defaulti18nOptions: I18nOptions = {
  legacy: false,
  globalInjection: true,
  locale: 'en',
  fallbackLocale: 'en',
  warnHtmlMessage: false,
  messages: {
    en: en as TMessageSchema,
  },
  missing: (locale, key, vm) => {
    const fileName = vm?.type.__file?.split('/').pop()
    const warningTitle = fileName ? `i18n/${fileName}` : 'i18n'
    const msg = `[${warningTitle}]: missing a translation key! ${locale} => ${key}`

    // If on prod, throw an error.
    // But do it in a timeout, to not break the current execution flow.
    if (import.meta.env.PROD || import.meta.env.MODE === 'stage') {
      setTimeout(() => {
        throw Error(msg)
      })
    }
    console.warn(msg)
  },
  modifiers: {
    // example to create custom modifiers
    // snakeCase: (str) => str.split(' ').join('_')
  },
}

// NOTE: false is needed here to initialize with Legacy = false to support modern mode types
export const i18n = createI18n<false>(defaulti18nOptions)

export const supportedLanguages: Array<TLanguage> = [
  {
    label: 'English',
    value: 'en',
  },
  {
    label: 'Deutsch',
    value: 'de',
  },
]

// load Naive UI translations & date time locales depending on i18n
export const naiveLocale = computed(() => {
  if (i18n.global.locale.value === 'de') return { locale: deDE, dateLocale: dateDeDE }

  // when undefined, Naive UI uses English by default
  return undefined
})

async function loadTranslation(locale: TLanguage['value']) {
  // load locale messages with dynamic import
  const messages = await import(`@/assets/locales/${locale}.json`)

  return messages.default as TMessageSchema
}

export async function setLanguage(locale: TLanguage['value'] = 'en', initialLoad = false) {
  if (!supportedLanguages.some((lang) => lang.value === locale)) {
    console.error(`[i18n]: Unsupported language: ${locale}`)
    return undefined
  }
  const authStore = useAuthStore()

  // if locale is not en, import requested locale dynamically
  // since en is loaded by default, no need to import it dynamically
  const messages = locale !== 'en' ? await loadTranslation(locale) : undefined

  // set locale messages first if there are messages to set
  if (messages) i18n.global.setLocaleMessage(locale, messages)

  // set locale
  i18n.global.locale.value = locale
  // set to localStorage
  localStorage.setItem('language', locale)

  // update user profile if it is not initial app load
  if (!initialLoad) await authStore.updateUser({ language: locale })

  // set axios headers
  API.defaults.headers.common['Accept-Language'] = locale

  // set to html
  document.querySelector('html')?.setAttribute('lang', locale)

  setLocale({
    mixed: {
      required: i18n.global.t('formValidation.required'),
    },
    number: {
      min: ({ min }) => i18n.global.t('formValidation.min', { min }),
      max: ({ max }) => i18n.global.t('formValidation.max', { max }),
      integer: () => i18n.global.t('formValidation.numeric'),
    },
    string: {
      email: i18n.global.t('formValidation.email'),
    },
  })

  return nextTick()
}
export function formatDate(date: Date | number | string, showYear = true) {
  if (!date) throw Error('date parameter cant be empty!')

  const dateToFormat = typeof date === 'string' || typeof date === 'number' ? new Date(date) : date

  // check if parsed date is not valid
  if (isNaN(Number(dateToFormat))) return null

  const locale = i18n.global.locale.value
  const options: Intl.DateTimeFormatOptions = showYear
    ? { year: 'numeric', month: 'short', day: 'numeric' }
    : { month: 'short', day: '2-digit' }

  // TO DO: Here we need to provide proper locale to make this work correctly
  // for now setting US locale as default (to get comma between month and year)
  return dateToFormat.toLocaleDateString(`${locale === 'en' ? 'en-US' : locale}`, options)
}

export function formatTime(date: Date | number | string, showSeconds = false) {
  if (!date) throw Error('date parameter cant be empty!')

  const dateToFormat = typeof date === 'number' || typeof date === 'string' ? new Date(date) : date

  // check if parsed date is not valid
  if (isNaN(Number(dateToFormat))) return null

  const locale = i18n.global.locale.value
  // TO DO: AM/PM difference depending on user locale
  const options: Intl.DateTimeFormatOptions = showSeconds
    ? { hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: false }
    : { hour: '2-digit', minute: '2-digit', hour12: false }

  return dateToFormat.toLocaleTimeString(locale, options)
}

export function formatDateAsSentence(date: Date | number | string) {
  const dateToFormat = typeof date === 'string' || typeof date === 'number' ? new Date(date) : date

  // check if parsed date is not valid
  if (isNaN(Number(dateToFormat))) return null

  const locale = i18n.global.locale.value as TLanguage['value']

  const locales = {
    en: dateEnGB,
    de: dateDeDE,
  }

  // NaiveUI also uses date-fns under the hood for date formatting, so we can use same locales
  return formatDistanceToNow(dateToFormat, {
    locale: locales[locale].locale,
    addSuffix: true,
  })
}

export function formatNumber(value: number, options: Intl.NumberFormatOptions = {}) {
  const locale = i18n.global.locale.value

  const defaultOpts: Intl.NumberFormatOptions = {
    maximumFractionDigits: 1,
    useGrouping: false,
  }

  return new Intl.NumberFormat(locale, merge(defaultOpts, options)).format(value)
}
